import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss']
})
export class Error403Component extends BaseComponent implements OnInit {

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit() {
  }

}
