import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromApp from '../../store/app.reducers';
import * as fromAuth from '../store/auth.reducers';
import * as AuthActions from '../store/auth.actions';
import { RememberPasswordService } from '../../core/header/dialogs/remember-password/remember-password.service';
import { BaseWebComponent } from '@web/base/shared/components/base-web/base-web.component';
import { SharedService } from '@web/base/shared/shared.service';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Constants } from '@web/base/shared/constants.class';
import { constants } from 'os';


@Component({
  selector: 'web-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends BaseWebComponent implements OnInit {


  @ViewChild('password', { static: true }) password: ElementRef;

  public URLS_TYPES_PRODUCTS = Constants.URLS_TYPES_PRODUCTS;
  public URLS_TYPES_COURSES = Constants.URLS_TYPES_COURSES;
  public URLS_TYPES_CONTACTS = Constants.URLS_TYPES_CONTACTS;

  section = 'signin';
  textLink;
  typeLink;
  returnURL;

  breadcrumbs = [{ label: 'signin', url: '', navigable: false }];

  public form: FormGroup;

  authState$: Observable<fromAuth.State>;

  constructor(
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    private rememberPasswordService: RememberPasswordService,
    public sharedService: SharedService,
    public router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public meta: Meta,
    public title: Title) {
    super(translate, null, null, meta, title, route, sharedService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.getTranslations(['core', 'signin']).then(() => {
      this.breadcrumbs[0].label = this.translations['signin']['title'];
      this.setBreadcrumbs();

      this.route.queryParams.subscribe(r => {
        this.returnURL = r.returnUrl;
        if (r.returnUrl.indexOf(Constants.URLS_TYPES_PRODUCTS) !== -1) {
          this.textLink = this.translations['signin']['textRegister'];
          this.typeLink = Constants.URLS_TYPES_PRODUCTS;
        } else {
          if (r.returnUrl[0].indexOf(Constants.URLS_TYPES_COURSES) !== -1 || r.returnUrl.indexOf(Constants.URLS_TYPES_COURSES) !== -1) {
            this.textLink = this.translations['signin']['textRegister'];
            this.typeLink = Constants.URLS_TYPES_COURSES;
          } else {
            if (r.returnUrl.indexOf(Constants.URLS_TYPES_CONTACTS) !== -1) {
              this.textLink = this.translations['signin']['textSubscription'];
              this.typeLink = Constants.URLS_TYPES_CONTACTS;
            } else {
              this.textLink = '';
              this.typeLink = null;
            }
          }
        }
      });
    });

    // this.subscriptions.push(this.authService.loggedIn$.subscribe(v => {
    //   if (v === true) {
    //     // Tras el logueo se devuelve a la página desde donde viene.
    //     this.route.queryParams.subscribe(r => {
    //       this.router.navigateByUrl(r.returnUrl);
    //     });
    //   }
    // }));

    if (this.typeLink === null) {
      this.authState$ = this.store.select('auth');
    }

    this.form = new FormGroup({
      username: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(1), Validators.maxLength(120)
      ])),
      password: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(60)]))
    });
  }

  login() {
    if (this.form.valid) {
      this.store.dispatch(new AuthActions.TrySignin(this.form.value));
    }
  }

  openRememberPasswordDialog() {
    // this.dialogRef.close();
    this.rememberPasswordService.openDialog();
  }

  showPass() {
    this.password.nativeElement.type = 'text';
  }

  hidePass() {
    this.password.nativeElement.type = 'password';
  }

  link() {
    switch (this.typeLink) {
      case Constants.URLS_TYPES_PRODUCTS:
        this.router.navigate(['usuarios', 'registro'], { queryParams: { returnUrl: this.returnURL } });
        break;
      case Constants.URLS_TYPES_COURSES:
        this.router.navigate(['usuarios', 'registro'], { queryParams: { returnUrl: this.returnURL } });
        break;
      case Constants.URLS_TYPES_CONTACTS:
        this.router.navigate(['informacion', 'suscripciones']);
        break;
    }
  }

}
