

import { IBAN } from './components/iban-input/iban.model';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { saveAs } from 'file-saver';

// Explicación: https://github.com/jvandemo/generator-angular2-library/issues/221
import * as moment_ from 'moment';
import { HttpResponse } from '@angular/common/http';
const moment = moment_;

export function downloadFile(response: HttpResponse<Blob>, filename: string) {
    const blob = new Blob([response.body], {
        type: response.headers.get('content-type')
    });

    saveAs(blob, filename);
}

export function checkForm(form: any) {
    // if (form instanceof FormGroup) {
    //     Object.keys(form.controls).forEach((field: any) => {
    //         const control = form.get(field);

    //         if (control instanceof FormControl) {
    //             control.markAsTouched({ onlySelf: true });
    //         } else if (control instanceof FormGroup) {
    //             checkForm(control);
    //         }
    //     });
    //     form.markAsTouched({ onlySelf: true });
    // } else if (form instanceof FormArray) {
    //     (<FormArray>form).controls.forEach(g => {
    //         checkForm(g);
    //     });
    // }
    form.markAllAsTouched();
}

export function createFormGroupFromObject(item) {
    const formGroup = new FormGroup({});

    Object.keys(item).forEach(k => {
        if (item[k] !== null && typeof item[k] === 'object') {
            formGroup.addControl(k, createFormGroupFromObject(item[k]));
        } else {
            formGroup.addControl(k, new FormControl(item[k]));
        }
    });

    return formGroup;
}

export function checkCC(num: IBAN) {
    const iban = num.iban + '' + num.field1 + '' + num.field2 + '' + num.field3 + '' + num.field4 + '' + num.field5;
    // if (iban.length > 20) { // viene con iban
    if (iban.length === 24) { // viene con iban
        if (checkCCC(iban.substr(4))) { // Comprobamos la cuenta
            // const currentIban = iban.substring(0, 4);
            const newIban = calculaIBAN(iban.substr(4));

            if (newIban.substr(0, 4) !== num.iban) { // Comprobamos el código del IBAN, y devolvemos la cuenta completa correcta.
                // return newIban;
                return false;
            }
            // return currentIban;
            return true;
        } else {
            return false;
        }
    }

    // else if (iban.length === 20) {
    //     if (checkCCC(iban)) { // Comprobamos la cuenta
    //         return calculaIBAN(iban); // Devolvemos la cuenta completa con el IBAN
    //     } else {
    //         return false;
    //     }
    // }

    return false;
}

function checkCCC(cccNumber) {
    let entity = cccNumber.substring(0, 4);
    let branch = cccNumber.substring(4, 8);
    let dc = cccNumber.substring(8, 10);
    let numberC = cccNumber.substring(10, 20);
    entity = completeZeros(entity, 4);
    branch = completeZeros(branch, 4);
    dc = completeZeros(dc, 2);
    numberC = completeZeros(numberC, 10);

    const concat = entity + branch;
    const dc1 = calcDCPartial(concat);
    const dc2 = calcDCPartial(numberC);

    return cccNumber && (dc === (dc1 + dc2));
}

function calcDCPartial(string: any) {
    let dcPartial = 0;
    const table = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
    let sum = 0;
    for (let i = 0; i < string.length; i++) {
        sum = sum + string.charAt(string.length - 1 - i) * table[i];
    }
    dcPartial = (11 - (sum % 11));
    if (dcPartial === 11) {
        dcPartial = 0;
    } else if (dcPartial === 10) {
        dcPartial = 1;
    }
    return dcPartial.toString();
}

function calculaIBAN(cccNumber) {
    const codCountry = 'ES';
    const entity = cccNumber.substring(0, 4);
    const branch = cccNumber.substring(4, 8);
    const dc = cccNumber.substring(8, 10);
    const numberC = cccNumber.substring(10, 20);
    const preIban = entity +
        branch +
        dc +
        numberC +
        getCharIBAN(codCountry.charAt(0)) +
        getCharIBAN(codCountry.charAt(1)) + '00';
    let dcIban: any = 98 - modulo(preIban, 97);
    dcIban = completeZeros(dcIban, 2);
    return codCountry + dcIban + entity + branch + dc + numberC;
}

export function getIbanLength(code: string): number {
    switch (code.toUpperCase()) {
        case 'ES': return 4; break; // En el caso de España, solo indicamos la longitud de la primera parte del IBAN.
        case 'AL': return 28; break;
        case 'DZ': return 24; break;
        case 'AD': return 24; break;
        case 'AO': return 25; break;
        case 'AT': return 20; break;
        case 'AZ': return 28; break;
        case 'BH': return 22; break;
        case 'BY': return 28; break;
        case 'BE': return 16; break;
        case 'BJ': return 28; break;
        case 'BA': return 20; break;
        case 'BR': return 29; break;
        case 'VG': return 24; break;
        case 'BG': return 22; break;
        case 'BF': return 27; break;
        case 'BI': return 16; break;
        case 'CM': return 27; break;
        case 'CV': return 25; break;
        case 'FR': return 27; break;
        case 'CG': return 27; break;
        case 'CR': return 21; break;
        case 'HR': return 21; break;
        case 'CY': return 28; break;
        case 'CZ': return 24; break;
        case 'DK': return 18; break;
        case 'DO': return 28; break;
        case 'EG': return 27; break;
        case 'EE': return 20; break;
        case 'FO': return 18; break;
        case 'FI': return 18; break;
        case 'FR': return 27; break;
        case 'FR': return 27; break;
        case 'FR': return 27; break;
        case 'GA': return 27; break;
        case 'GE': return 22; break;
        case 'DE': return 22; break;
        case 'GI': return 23; break;
        case 'GR': return 27; break;
        case 'GL': return 18; break;
        case 'FR': return 27; break;
        case 'GT': return 28; break;
        case 'GG': return 22; break;
        case 'HU': return 28; break;
        case 'IS': return 26; break;
        case 'IR': return 26; break;
        case 'IQ': return 23; break;
        case 'IE': return 22; break;
        case 'IM': return 22; break;
        case 'IL': return 23; break;
        case 'IT': return 27; break;
        case 'CI': return 28; break;
        case 'JE': return 22; break;
        case 'JO': return 30; break;
        case 'KZ': return 20; break;
        case 'XK': return 20; break;
        case 'KW': return 30; break;
        case 'LV': return 21; break;
        case 'LB': return 28; break;
        case 'LI': return 21; break;
        case 'LT': return 20; break;
        case 'LU': return 20; break;
        case 'MK': return 19; break;
        case 'MG': return 27; break;
        case 'ML': return 28; break;
        case 'MT': return 31; break;
        case 'FR': return 27; break;
        case 'MR': return 27; break;
        case 'MU': return 30; break;
        case 'MD': return 24; break;
        case 'MC': return 27; break;
        case 'ME': return 22; break;
        case 'MZ': return 25; break;
        case 'NL': return 18; break;
        case 'FR': return 27; break;
        case 'NO': return 15; break;
        case 'PK': return 24; break;
        case 'PS': return 29; break;
        case 'PL': return 28; break;
        case 'PT': return 25; break;
        case 'QA': return 29; break;
        case 'FR': return 27; break;
        case 'RO': return 24; break;
        case 'LC': return 32; break;
        case 'FR': return 27; break;
        case 'SM': return 27; break;
        case 'ST': return 25; break;
        case 'SA': return 24; break;
        case 'SN': return 28; break;
        case 'RS': return 22; break;
        case 'SC': return 31; break;
        case 'SK': return 24; break;
        case 'SI': return 19; break;
        case 'SE': return 24; break;
        case 'CH': return 21; break;
        case 'TL': return 23; break;
        case 'TN': return 24; break;
        case 'TR': return 26; break;
        case 'UA': return 29; break;
        case 'AE': return 23; break;
        case 'GB': return 22; break;
        case 'VA': return 22; break;
        case 'FR': return 27; break;
        default: return 32;
    }
}

function completeZeros(number, long) {
    let ceros = '';
    number = number.toString();
    for (let i = 0; (long - number.length) > i; i++) {
        ceros += '0';
    }
    return ceros + number;
}

function modulo(preIBAN, divisor) {

    let rest = 0;
    for (let i = 0; i < preIBAN.length; i += 10) {
        const dib: any = rest + '' + preIBAN.substr(i, 10);
        rest = dib % divisor;
    }

    return rest;
}

function getCharIBAN(character) {
    let peso = '';
    character = character.toUpperCase();
    switch (character) {
        case 'A':
            peso = '10';
            break;
        case 'B':
            peso = '11';
            break;
        case 'C':
            peso = '12';
            break;
        case 'D':
            peso = '13';
            break;
        case 'E':
            peso = '14';
            break;
        case 'F':
            peso = '15';
            break;
        case 'G':
            peso = '16';
            break;
        case 'H':
            peso = '17';
            break;
        case 'I':
            peso = '18';
            break;
        case 'J':
            peso = '19';
            break;
        case 'K':
            peso = '20';
            break;
        case 'L':
            peso = '21';
            break;
        case 'M':
            peso = '22';
            break;
        case 'N':
            peso = '23';
            break;
        case 'O':
            peso = '24';
            break;
        case 'P':
            peso = '25';
            break;
        case 'Q':
            peso = '26';
            break;
        case 'R':
            peso = '27';
            break;
        case 'S':
            peso = '28';
            break;
        case 'T':
            peso = '29';
            break;
        case 'U':
            peso = '30';
            break;
        case 'V':
            peso = '31';
            break;
        case 'W':
            peso = '32';
            break;
        case 'X':
            peso = '33';
            break;
        case 'Y':
            peso = '34';
            break;
        case 'Z':
            peso = '35';
            break;
    }
    return peso;
}


export function downloadURL(url, fileName?, target?) {
    if (url !== 'error') {
        if (!fileName) {
            fileName = 'vg-' + moment().format('DD-MM-YYYY');
        }
        const link = document.createElement('a');
        link.title = fileName;

        // link.setAttribute('download', fileName);
        if (target) {
            // link.setAttribute('target', '_blank');
            link.target = '_blank';
        }

        link.download = 'true';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}


export function googleAddress($event) {

    let street = '',
        street_number = '',
        city = '',
        province = '',
        country = '',
        postal_code = '',
        lat = '',
        lng = '';

    $event.address_components.forEach((comp) => {
        if (comp.types.indexOf('street_number') !== -1) {
            street_number = ', ' + comp.long_name;
        } else if (comp.types.indexOf('route') !== -1) {
            street = comp.long_name;
        } else if (comp.types.indexOf('locality') !== -1 && comp.types.indexOf('political') !== -1) {
            city = comp.long_name;
        } else if (comp.types.indexOf('administrative_area_level_2') !== -1) {
            province = comp.long_name;
        } else if (comp.types.indexOf('country') !== -1) {
            country = comp.long_name;
        } else if (comp.types.indexOf('postal_code') !== -1) {
            postal_code = comp.long_name;
        }
    });

    lat = $event.geometry.location.lat();
    lng = $event.geometry.location.lng();

    return {
        address: street + ' ' + street_number,
        locality: city,
        province,
        cp: postal_code,
        lat: lat,
        lng: lng
    };

}

export function order(a, b, field?: string, order?: string) {
    field = field || 'order';
    order = order || 'asc';

    if (a[field] < b[field]) {
        if (order === 'asc') {
            return -1;
        } else {
            return 1;
        }
    } else if (a[field] > b[field]) {
        if (order === 'asc') {
            return 1;
        } else {
            return -1;
        }
    } else {
        return 0;
    }
}

export function getCleanedString(str) {
    // Definimos los caracteres que queremos eliminar
    const specialChars = '!@#$^&%*()+=-[]\/{}|:<>?,';

    // Los eliminamos todos
    for (let i = 0; i < specialChars.length; i++) {
        str = str.replace(new RegExp('\\' + specialChars[i], 'gi'), '');
    }

    // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
    str = str.replace(/á/gi, 'a');
    str = str.replace(/é/gi, 'e');
    str = str.replace(/í/gi, 'i');
    str = str.replace(/ó/gi, 'o');
    str = str.replace(/ú/gi, 'u');
    str = str.replace(/ñ/gi, 'n');
    return str;
}
