import { CookiesService } from './../cookies/cookies.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { publicS3Url } from './../../../../environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'utils';
import { SwUpdate } from '@angular/service-worker';
import { SharedService } from '@web/shared/shared.service';
import { getCookie } from '@web/shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { CoreService } from '../core.service';
import { MenuService } from '../menu/menu.service';
import { Subscription } from 'rxjs';
import { IResponse } from '@web/base/shared/models/response.model';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { order } from 'utils';
import { TemplateDialogService } from './dialogs/template-dialog.service';

@Component({
  selector: 'web-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  providers: [TemplateDialogService]
})
export class TemplateComponent extends BaseComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;

  public showCookiesInfo = false;
  public showSidenav = true;
  public showContent = false;

  public sidenavStatusSubscription: Subscription;

  public bannersSidenav = [];
  BANNER_SIDE = ConstantsProject.BANNER_SIDE;

  public publicS3Url = publicS3Url;

  public showTemplate = false;

  constructor(
    translate: TranslateService,
    dialog: MatDialog,
    public sharedService: SharedService,
    private coreService: CoreService,
    private menuService: MenuService,
    private swUpdate: SwUpdate,
    public route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public router: Router,
    public cookiesService: CookiesService,
    public templateDialogService: TemplateDialogService
  ) {
    super(translate, null, dialog);

    breakpointObserver.observe(['(max-width: 959px)']).subscribe(res => {
      this.coreService.setIsMobile(res.matches);
      this.coreService.setSidenavStatus(false);
    });

    // Registros de iconos para usar
    iconRegistry.addSvgIcon('ico-linked-in', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-linked-in.svg'));
    iconRegistry.addSvgIcon('ico-whatsapp', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-whatsapp.svg'));
    iconRegistry.addSvgIcon('ico-pinterest', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-pinterest.svg'));
    iconRegistry.addSvgIcon('ico-reddit', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-reddit.svg'));
    iconRegistry.addSvgIcon('ico-twitter', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-twitter.svg'));
    iconRegistry.addSvgIcon('ico-email', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-email.svg'));
    iconRegistry.addSvgIcon('ico-facebook', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-facebook.svg'));
    iconRegistry.addSvgIcon('ico-google-plus', sanitizer.bypassSecurityTrustResourceUrl('./../../../assets/img/shared-icons/ico-google-plus.svg'));
  }

  ngOnInit() {

    // Cookies ------------------------------
    this.cookiesService.showCookiesConsent.subscribe(v => {
      this.showCookiesInfo = v;
    });
    if (getCookie(ConstantsProject.COOKIES_CONSENT) !== null) {
      this.cookiesService.showCookiesConsent.next(false);
      this.showCookiesInfo = false;
    } else {
      this.showCookiesInfo = true;
    }
    // --------------------------------------

    this.subscriptions.push(
      this.sharedService.showSidenav$.subscribe(v => {
        setTimeout(() => (this.showSidenav = v));
      })
    );

    this.subscriptions.push(this.sharedService.showTemplate$.subscribe(v => {
      if (this.showTemplate !== v) {
        setTimeout(() => {
          this.showTemplate = v;
        }, 100);
      }
    }));

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(evt => {
        console.log('service worker updated');
      });

      this.swUpdate
        .checkForUpdate()
        .then(() => {
          // noop
        })
        .catch(err => {
          console.error('error when checking for update', err);
        });
    }

    this.getTranslations(['core']);

    this.menuService.createMenu();

    this.subscriptions.push(
      this.coreService.templateLoaded$.subscribe((v: IResponse) => {

        if (v) {
          // Banners
          this.bannersSidenav = v.result.banners
            .filter(b => b.bannerPositionID === this.BANNER_SIDE)
            .sort((a, b) => order(a, b));
          this.coreService.setBannersSidenav(this.bannersSidenav);

          // Ventanas emergentes
          this.route.queryParams.subscribe((params: Params) => {
            const popupId = params['showPopupId'];
            if (popupId) {
              this.sharedService
                .loadPopup(popupId)
                .subscribe((res: IResponse) => {
                  this.dialog.open(PopupDialogComponent, {
                    panelClass: ['dialog', 'popup-dialog'],
                    width: '95%',
                    maxWidth: '750px',
                    data: { popup: res.result }
                  });
                });
            } else {
              v.result.popups.map(p => {
                if (p && !getCookie(ConstantsProject.COOKIES_PREFIX + 'popup_' + p.popupID)) {
                  this.dialog.open(PopupDialogComponent, {
                    panelClass: ['dialog', 'popup-dialog'],
                    width: '95%',
                    maxWidth: '750px',
                    data: { popup: p }
                  });
                }
              });
            }
          });

          // Usuario
          if (v.result.user) {
            // Quieren que los colegiados actualicen cada año si son colegiados o no, pongo esto aquí porque en las jornadas es donde más entran.
            if (v.result.user.askIsCollegiate) {
              this.templateDialogService.openDialogCollegiateUpdate().subscribe(result => {
                if (result) {
                  const value: any = result;
                  value.emailUser = v.result.user.email;
                  this.sharedService.updateCollegiate(v.result.user.userWebID, value).subscribe();
                }
              });
            }
          }

        }
      })
    );

    this.coreService.loadTemplate();

    // Cerrar el sidenav si pasamos de 'resolución movil' a PC
    this.sidenavStatusSubscription = this.coreService
      .getSidenavStatus()
      .subscribe(status => {
        if (status === false) {
          this.sidenav.close();
        } else {
          this.sidenav.open();
        }
      });

    this.showContent = true;
  }

  openCookiesPolicy() {
    this.router.navigateByUrl("cookies");
  }

  onSidenavClosedStart() {
    this.coreService.setSidenavStatus(false);
  }

  onSidenavOpenedStart() {
    this.coreService.setSidenavStatus(true);
  }

  onActivate(event) {
    // window.scroll(0, 0);
  }
}
