import { MatIconRegistry } from '@angular/material/icon';
import { IBaseListWebItem } from './../../shared/components/base-list-web/base-list-web-item.model';
import { CoreService } from '@web/base/core/core.service';
import { BaseWebComponent } from '@web/shared/components/base-web/base-web.component';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@web/base/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponse } from '@web/base/shared/models/response.model';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { order } from 'utils';
import { Subject } from 'rxjs';
import { URL_WEB } from './../../../../environments/environment';
import { SlugifyPipe } from '@web/base/shared/pipes/slugify.pipe';
import { AuthService } from '@web/base/auth/auth.service';

@Component({
  selector: 'web-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseWebComponent implements OnInit {
  section = 'home';
  showSidenav = false;
  bannersHeader = [];
  queries = [];
  queriesBestRatings = [];

  private sliderContent = new Subject<Array<IBaseListWebItem>>();
  public sliderContent$ = this.sliderContent.asObservable();

  private gridContent = new Subject<Array<IBaseListWebItem>>();
  public gridContent$ = this.gridContent.asObservable();

  private listContent = new Subject<Array<IBaseListWebItem>>();
  public listContent$ = this.listContent.asObservable();

  private contentsObservatoriesPortals = new Subject<Array<IBaseListWebItem>>();
  public contentsObservatoriesPortals$ = this.contentsObservatoriesPortals.asObservable();

  CONTENTS_OBSERVATORIES_PORTALS = ConstantsProject.CONTENTS_OBSERVATORIES_PORTALS;
  CONTENTS_NEWS = ConstantsProject.CONTENTS_NEWS;
  CONTACTS_FEATURED = ConstantsProject.CONTACTS_FEATURED;
  CONTACTS_BEST_RATINGS = ConstantsProject.CONTACTS_BEST_RATINGS;

  constructor(
    translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public coreService: CoreService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public slugPipe?: SlugifyPipe,
    private authService?: AuthService,
    public router?: Router
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);

    iconRegistry.addSvgIcon('ico_observatorios', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_observatorios.svg'));
    iconRegistry.addSvgIcon('ico_observatorios_novedades', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_observatorios_novedades.svg'));
    iconRegistry.addSvgIcon('ico_consultas', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_consultas.svg'));
    iconRegistry.addSvgIcon('ico_formacion_descentralizada', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_formacion_descentralizada.svg'));
    iconRegistry.addSvgIcon('ico_formacion_online', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_formacion_online.svg'));
    iconRegistry.addSvgIcon('ico_formacion_presencial', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_formacion_presencial.svg'));
    iconRegistry.addSvgIcon('ico_biblioteca', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_biblioteca.svg'));
    iconRegistry.addSvgIcon('ico_novedades', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_novedades.svg'));
    iconRegistry.addSvgIcon('ico_consultas_destacadas', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_consultas_destacadas.svg'));
    iconRegistry.addSvgIcon('ico_tienda', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_tienda_network.svg'));
    iconRegistry.addSvgIcon('ico_covid19', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_covid19.svg'));
    iconRegistry.addSvgIcon('ico_fondos_eu', sanitizer.bypassSecurityTrustResourceUrl(URL_WEB + 'assets/img/ico_fondos_eu.svg'));
  }

  ngOnInit() {
    super.ngOnInit();

    this.coreService.loadCover().subscribe((v: IResponse) => {

      // Banners
      this.bannersHeader = v.result.banners
        .filter(b => b.bannerPositionID === ConstantsProject.BANNER_SIDE)
        .sort((a, b) => order(a, b));

      // Elementos de la portada
      this.sliderContent.next(v.result.sliderContent);
      this.gridContent.next(v.result.gridContent);
      this.listContent.next(v.result.listContent);

      // Últimas actualizaciones de Observatorios y Portales
      this.contentsObservatoriesPortals.next(v.result.contentsObservatoriesPortals);

      // Consultas destacadas
      this.queries = v.result.queries;
      this.queries.map(e => {
        e.url = '/consultas/' + e.contactTypeID + '/' + this.slugPipe.transform(e.contactType) + '/consulta/' + e.contactID + '/' + this.slugPipe.transform(e.subject);
        return e;
      });

      // Consultas mejor valoradas
      this.queriesBestRatings = v.result.queriesBestRatings;
      this.queriesBestRatings.map(e => {
        e.url = '/consultas/' + e.contactTypeID + '/' + this.slugPipe.transform(e.contactType) + '/consulta/' + e.contactID + '/' + this.slugPipe.transform(e.subject);
        return e;
      });

    });
  }

  handleToBanner(url) {
    if (this.authService.user && (this.authService.user.fhn || this.authService.user.observatories.length >= 0)) {
      this.sharedService.loadURLBanner(url, this.authService.user.email).subscribe(res => {
        if (res) {
          const link = document.createElement('a');
          link.title = 'vg-tirant';
          link.href = res['result'];
          link.target = '_blank';
          link.download = 'true';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    } else {
      this.router.navigateByUrl('p/112515/biblioteca');
    }

  }

  more(type) {

    // Llamamos a la url y le pasamos el nombre de la sección
    this.getTranslations(['core', 'home']).then(() => {

      switch (type) {
        case this.CONTENTS_OBSERVATORIES_PORTALS:
          this.router.navigateByUrl('contenidos/mostrar/todos/' + type + '/' + this.slugPipe.transform(this.translations['home']['latestUpdates']));
          break;

        case this.CONTENTS_NEWS:
          this.router.navigateByUrl('contenidos/mostrar/todos/' + type + '/' + this.slugPipe.transform(this.translations['home']['news']));
          break;

        // María. 17/03/2023. Se añade el bóton más en consultas destacadas
        case this.CONTACTS_FEATURED:
          this.router.navigateByUrl('contenidos/mostrar/todos/' + type + '/' + this.slugPipe.transform(this.translations['home']['featuredQueries']));
          break;

        // María. 17/03/2023. Se añade el bóton más en consultas mejor valoradas
        case this.CONTACTS_BEST_RATINGS:
          this.router.navigateByUrl('contenidos/mostrar/todos/' + type + '/' + this.slugPipe.transform(this.translations['home']['ratingQueries']));
          break;

        default:
          break;
      }
    });

  }
}
