import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';

import { RememberPasswordComponent } from './remember-password.component';


@Injectable()
export class RememberPasswordService {

  constructor(private dialog: MatDialog) { }

  openDialog() {
    let dialogRef: MatDialogRef<RememberPasswordComponent>;
    dialogRef = this.dialog.open(RememberPasswordComponent, { panelClass: 'dialog' });
    return dialogRef.afterClosed();
  }

}
