import { Routes } from '@angular/router';
import { Error404Component } from './error-404/error-404.component';
import { Error403Component } from './error-403/error-403.component';
import { HomeComponent } from './home/home.component';

import { CookiesComponent } from './cookies/cookies.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent /* , data: { section: 'home' } */ },
  { path: '403', component: Error403Component },
  { path: '404', component: Error404Component },
  { path: 'cookies', component: CookiesComponent, data: { section: 'cookies' }, },
  {
    path: '',
    loadChildren: () => import('../../project/web/web.module').then(m => m.WebModule)
  },
  { path: '**', redirectTo: '404' }

];
