import { getCookie } from '@web/base/shared/utils';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ConstantsProject } from './project/shared/constants.class';
declare var ga: Function;

@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router, viewportScroller: ViewportScroller) {

    var cookie = JSON.parse(getCookie(ConstantsProject.COOKIES_CONSENT));
    if (cookie) { var statsCookies = cookie.stats; }

    this.router.events.subscribe(event => {
      // if (statsCookies) {
      //   if (event instanceof NavigationEnd) {
      //     ga('set', 'page', event.urlAfterRedirects);
      //     ga('send', 'pageview');
      //   }
      // }

      if (event instanceof Scroll) {
        if (event.position) {
          // backward navigation
          viewportScroller.scrollToPosition(event.position);

          // Hacemos esto esperando 1.5 segundos por si la pantalla tarda en estar completamente disponible.
          setTimeout(() => {
            viewportScroller.scrollToPosition(event.position);
          }, 1500);
        } else if (event.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });
  }

  public ngOnInit(): void { }
}
