import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'utils';
import { Observable } from 'rxjs';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent extends BaseComponent implements OnInit {

  @Input() attachments: Observable<Array<any>>;
  @Input() arrayAttachments: Array<any>;

  @Input() titleImages: string;
  @Input() titleVideos: string;
  @Input() titleAttachments: string;

  files: Array<any>;
  images: Array<any>;
  videos: Array<any>;

  constructor(
    translate: TranslateService
  ) {
    super(translate);
  }

  ngOnInit() {
    if (this.arrayAttachments) {
      this.files = this.arrayAttachments.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_FILE);
      this.images = this.arrayAttachments.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_IMAGE);
      this.videos = this.arrayAttachments.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_VIDEO);
    } else {
      this.subscriptions.push(this.attachments.subscribe(atts => {
        this.files = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_FILE);
        this.images = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_IMAGE);
        this.videos = atts.filter(a => a.type === ConstantsProject.ATTACHMENT_TYPE_VIDEO);
      })); 
    }
  }

}
