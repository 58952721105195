import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss']
})
export class Error404Component extends BaseComponent implements OnInit {

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit() {
  }
}
