import { NgModule } from '@angular/core';
import { SimpleSnackbarComponent } from './components/simple-snackbar/simple-snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IbanInputComponent } from './components/iban-input/iban-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { MoveNextOnCompleteDirective } from './directives/move-next-on-complete.directive';
import { BaseComponent } from './components/base/base.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FlexLayoutModule
  ],
  declarations: [
    BaseComponent,
    SimpleSnackbarComponent,
    SimpleDialogComponent,
    IbanInputComponent,
    TimeInputComponent,
    MoveNextOnCompleteDirective,
  ],
  exports: [
    BaseComponent,
    SimpleSnackbarComponent,
    SimpleDialogComponent,
    IbanInputComponent,
    TimeInputComponent,
    FlexLayoutModule,
  ],
  entryComponents: [
    SimpleSnackbarComponent,
    SimpleDialogComponent,
    // IbanInputComponent,
  ]
})
export class UtilsModule { }
