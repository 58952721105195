import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'utils';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { checkForm } from 'utils';
import { DomSanitizer } from '@angular/platform-browser';
import { IAttachment } from '../../attachment.model';
import { SharedService } from '@web/base/shared/shared.service';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { getCleanedString } from '../../../../../../../../../../libraries/utils/src/lib/utils';

@Component({
  selector: 'web-edit-attachment',
  templateUrl: './edit-attachment.component.html',
  styleUrls: ['./edit-attachment.component.scss']
})
export class EditAttachmentComponent extends BaseComponent implements OnInit {

  public index: number;
  public formData: FormData;
  // public attachment: IAttachment;
  public attachments: Array<IAttachment>;
  public canActive = true;
  public acceptedFiles = '';

  public form: FormGroup;

  public fileUploaded;

  public maxSizeAttachment = ConstantsProject.MAX_SIZE_ATTACHMENTS;

  ATTACHMENT_TYPE_FILE = ConstantsProject.ATTACHMENT_TYPE_FILE;

  // public safeVideoUrl = null;
  // public videoImagePreview = null;

  constructor(
    public translate: TranslateService,
    // public sharedService: SharedService,
    // public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<EditAttachmentComponent>) {
    super(translate);
  }

  ngOnInit() {

    this.createForm();

    if (this.index !== undefined && this.attachments[this.index]) {
      this.setForm(this.attachments[this.index]);
    }
  }

  close(val?) {
    this.dialogRef.close(val);
  }

  createForm() {
    this.form = new FormGroup({
      attachmentID: new FormControl(null),
      type: new FormControl(this.ATTACHMENT_TYPE_FILE, [Validators.required]),
      title: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(125)]),
      hash: new FormControl(''),
      url: new FormControl(''),
      videoUrl: new FormControl(''),
      order: new FormControl(0),
      active: new FormControl(true),
    });

  }

  setForm(attachment: IAttachment) {
    Object.assign(attachment, { videoUrl: '' });
    this.form.patchValue(attachment);
  }

  onFile(file: any) {
    this.formData.delete('file_' + this.index);
    this.fileUploaded = file;
    console.log(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ((e: any) => {
      // this.fileSelected = e.target.result;
      this.form.controls['url'].setValue(e.target.result);
    });

    this.formData.append('file_' + this.index, file, getCleanedString(file.name));
  }

  submit() {
    checkForm(this.form);

    if (this.form.valid) {
      if (this.index !== undefined) {
        // attachment = res.attachment;
        // attachment.pending = true;
        if (this.formData.has('file_' + this.index)) {
          if (this.formData.has('file_' + this.index)) {
            this.formData.set('file_' + this.index, this.formData.get('file_' + this.index));
          } else {
            this.formData.append('file_' + this.index, this.formData.get('file_' + this.index));
          }
        }

        this.attachments[this.index] = Object.assign({ pending: true }, this.form.value);
      } else {
        this.attachments.push(this.form.value);
        this.formData.append('file_' + this.index, this.formData.get('file_' + this.index));
      }

      this.close({ attachment: this.form.value, data: this.formData });
    }
  }
}
