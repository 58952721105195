import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent extends BaseComponent implements OnInit {

  @Output('click') click: EventEmitter<any> = new EventEmitter<any>(null);

  @Input() msg: string;
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() loadingMsg: string;
  @Input() class: string;
  @Input() color = 'accent';

  constructor(
    translate: TranslateService
  ) {
    super(translate);
  }

  ngOnInit() {
    this.getTranslations(['general']).then(() => {
      if (!this.msg) {
        this.msg = this.translations['general']['save'];
      }

      if (!this.loadingMsg) {
        this.loadingMsg = this.translations['general']['saving'];
      }
    });
  }

  submit() {
    this.click.emit(null);
  }
}

