import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { IBreadCrumb } from './models/breadcrumb.model';
import { HttpClient } from '@angular/common/http';
import { URL } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { CoreService } from '../core/core.service';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Injectable()
export class SharedService {
  loading = false;

  // public reloadBreadcrumbs = new Subject<Array<IBreadCrumb>>();
  public reloadBreadcrumbs = new BehaviorSubject<Array<IBreadCrumb>>([]);
  public reloadBreadcrumbs$ = this.reloadBreadcrumbs.asObservable();

  public showSidenav = new Subject<boolean>();
  public showSidenav$ = this.showSidenav.asObservable();

  public showTemplate = new Subject<boolean>();
  public showTemplate$ = this.showTemplate.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private coreService: CoreService,
    private router: Router
  ) { }

  loadPopup(id) {
    const url = `${URL}/popups/${id}`;
    return this.http.get(url);
  }

  loadObservatories(purchasable) {
    const url = `${URL}/observatories/find`;
    return this.http.post(url, { purchasable });
  }

  loadObservatory(observatoryID) {
    const url = `${URL}/observatories/${observatoryID}`;
    return this.http.get(url);
  }

  loadPartnersObservatory(observatoryID) {
    const url = `${URL}/observatories-partners/${observatoryID}`;
    return this.http.get(url);
  }

  loadProductsCategories() {
    const url = `${URL}/products-categories/find`;
    return this.http.post(url, {});
  }

  loadContactTypes() {
    const url = `${URL}/contacts-types/find`;
    return this.http.post(url, {});
  }

  loadContactSubtypes(contactTypeID) {
    const url = `${URL}/contacts-subtypes/find`;
    return this.http.post(url, {});
  }

  loadEntities(code: string) {
    const url = `${URL}/entities/findByCode`;
    return this.http.post(url, { code });
  }

  loadCommunity() {
    const url = `${URL}/communities/find`;
    return this.http.post(url, { });
  }

  codeDiscountValid(code, elementType, elementID) {
    const url = `${URL}/discounts/valid`;
    return this.http.post(url, { code, elementType, elementID });
  }

  // Localities / province / community / country
  loadLocaties(cp: any) {
    const url = `${URL}/localities/find`;
    return this.http.post(url, { filter: cp });
  }

  loadURLBanner(urlBanner, email) {
    const url = `${URL}/banners/handleUrl`;
    return this.http.post(url, { url: urlBanner, email });
  }

  loadDateCutOff() {
    const url = `${URL}/subscriptions/date-cutoff`;
    return this.http.post(url, { });
  }

  /**
   * Función para comprobar si se puede acceder a una sección.
   * Si el usuario no está logueado, directamente va al login.
   * El parámetro fhn indica si el usuario logueado debe estar suscrito a FHN para acceder a la sección.
   * El parámetro observatoryID indica si el usuario debe estar suscrito a FHN o al observatorio en cuestión
   * para acceder a la sección.
   * @param subscriber
   * @param fhn
   * @param observatoryID
   */
  checkPrivateAccess(subscriber, fhn, observatoryID) {
    return new Promise((resolve, reject) => {
      if (subscriber || fhn || observatoryID) {
        this.coreService.templateLoaded$.subscribe(t => {
          if (t) {
            if (!t.result.user || !t.result.user.userWebID) {
              if (this.router.url.substr(0, 7) !== '/login?') {
                this.router.navigate(['login'], { replaceUrl: true, queryParams: { returnUrl: this.router.url } });
              }
            } else {
              if (subscriber && !t.result.user.fhn && t.result.user.observatories.length === 0) {
                // Redirigimos a la pantalla de suscripciones, mostrando el mensaje de que es necesario que sea suscriptor para acceder.
                this.router.navigate(['informacion/suscripciones'], { queryParams: { subscriberRequired: true } });
              } else {
                if (fhn && !observatoryID && !t.result.user.fhn) {
                  // Redirigimos a la pantalla de suscripciones, mostrando el mensaje de que es necesario que sea FHN para acceder.
                  this.router.navigate(['informacion/suscripciones'], { queryParams: { fhnRequired: true } });
                }

                if (observatoryID && (t.result.user.observatories.findIndex(o => o === observatoryID) < 0 && !t.result.user.fhn)) {
                  // Redirigimos a la pantalla de suscripciones, mostrando el mensaje de que es necesario que sea FHN para acceder.
                  this.router.navigate(['informacion/suscripciones'], { queryParams: { observatoryRequired: observatoryID } });
                }
              }
            }

            resolve();
          }

        }, err => reject(err));
      } else {
        resolve();
      }
    });
  }

  loadWayPaysByAddressID(userAddressID: any) {
    const url = `${URL}/ways-pays/find`;
    return this.http.post(url, { userAddressID: userAddressID, visible: true });
  }

  loadWayPaysByDocumentType(documentType: any) {
    const url = `${URL}/ways-pays/type-find`;
    return this.http.post(url, { documentType: documentType, visible: true });
  }

  loadWayPays() {
    const url = `${URL}/ways-pays/all-find`;
    return this.http.post(url, { visible: true });
  }

  loadUserAddresses() {
    const url = `${URL}/users-web-addresses/find`;
    return this.http.post(url, {});
  }

  // Esta función se encarga de procesar la galería de adjuntos antes de subirlos.
  // El último parámetro indica si se deben procesar cada uno de los adjuntos.

  handleAttachments(attachments, formData) {
    formData.delete('attachments');

    const atts = [];
    // const attsDelete = [];

    attachments.forEach((element, index) => {
      element.url = null;

      // Si queremos eliminar el archivo

      if (element.delete) {

        // Si el archivo aún no existe, simplemente eliminamos la clave para que no lo cree.
        // if (handleFiles && formData.has('file_' + index)) {
        //   // console.log('tiene para borrar ' + index);

        //   formData.delete('file_' + index);
        // }

        // attsDelete.push(element);
        element.deleted = true;
        atts.push(element);
      } else {

        atts.push(element);

        // Si el archivo aún no existe, creamos la clave con el índice siguiente al que ya existe.

        // if (handleFiles && formData.has('file_' + index)) {
        //   // console.log('tiene para crear ' + index);
        //   formData.append('file_' + atts.length, formData.get('file_' + index));
        //   formData.delete('file_' + index);

        // }
      }
    });

    formData.append('attachments', JSON.stringify(atts));
    // formData.append('attachments', atts);
    // formData.append('attachmentsDelete', JSON.stringify(attsDelete));
  }

  // Hacemos el update de colegiado
  updateCollegiate(itemID: number, item) {
    const url = `${URL}/users-web/${itemID}/is-collegiate-update`;
    return this.http.post(url, item);
  }

  loadProvinces() {
    const url = `${URL}/provinces/find`;
    return this.http.post(url, { });
  }


}
