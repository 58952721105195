import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL } from '../../../../../environments/environment';
import { preparePagination } from '@web/shared/utils';

@Injectable()
export class BaseListWebService {

    public pathBase: string;

    constructor(
        public http: HttpClient
    ) {
    }

    setBase(base: string) {
        this.pathBase = `${URL}/${base}`;
    }

    load(filter?: any, paginate?: boolean, truncate?: number) {
        const url = `${this.pathBase}/find`;
        const values = preparePagination(filter, paginate);
        values.pagination.params['truncate'] = truncate;
        return this.http.post(url, values.values, values.pagination);
    }
}
