import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './auth.service';
import { SigninComponent } from './signin/signin.component';
import { RememberPasswordService } from '../core/header/dialogs/remember-password/remember-password.service';

@NgModule({
  declarations: [
    SigninComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthRoutingModule,
    SharedModule
  ],
  providers: [
    AuthService,
    RememberPasswordService
  ]
})
export class AuthModule { }
