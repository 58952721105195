import { ShareToolbarComponent } from './components/share-toolbar/share-toolbar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BaseWebComponent } from './components/base-web/base-web.component';
import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient /*, HttpClientModule */ } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UtilsModule } from 'utils';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { SharedService } from './shared.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { SafeHtmlPipe } from '@web/base/shared/pipes/safe-html.pipe';

import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { BaseListWebComponent } from './components/base-list-web/base-list-web.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { ImagesModuleComponent } from './components/images-module/images-module.component';
import { ImageGalleryComponent } from './components/images-module/image-gallery/image-gallery.component';
import { ImageGalleryService } from './components/images-module/image-gallery/service/image-gallery.service';
import { AttachmentsModuleComponent } from './components/attachments-module/attachments-module.component';
import { VideosModuleComponent } from './components/videos-module/videos-module.component';
import { VideoDialogComponent } from './components/videos-module/video-dialog/video-dialog.component';
import { EmbedVideoService } from './components/videos-module/embed-video-service';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';

import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RECAPTCHA_KEY } from '../../../environments/environment';
import { SectionTitleDinamicComponent } from './components/section-title-dinamic/section-title-dinamic.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './components/calendar/calendar.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachmentsHandleComponent } from './components/attachments-handle/attachments-handle.component';
import { MatBadgeModule } from '@angular/material';
import { EditAttachmentComponent } from './components/attachments-handle/dialogs/edit-attachment/edit-attachment.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { AddThisComponent } from './components/addthis/addthis.component';
import { LoadingComponent } from './components/loading/loading.component';
import { RouterModule } from '@angular/router';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeEs, 'es-ES', localeEsExtra);

@NgModule({
  imports: [
    CommonModule,
    // HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    UtilsModule,
    MatNativeDateModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatListModule,
    MatGridListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSliderModule,
    MatStepperModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    OverlayModule,
    NgxPaginationModule,
    CarouselModule,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatMomentDateModule,
    MatBadgeModule,
    RouterModule
  ],
  declarations: [
    SafeHtmlPipe,
    FileSizePipe,
    TruncatePipe,
    BaseWebComponent,
    SectionTitleComponent,
    SectionTitleDinamicComponent,
    BaseListWebComponent,
    AttachmentsComponent,
    ImagesModuleComponent,
    ImageGalleryComponent,
    AttachmentsModuleComponent,
    VideosModuleComponent,
    VideoDialogComponent,
    LoadingButtonComponent,
    CalendarComponent,
    PurchaseComponent,
    AttachmentsHandleComponent,
    EditAttachmentComponent,
    FileInputComponent,
    ShareToolbarComponent,
    AddThisComponent,
    SlugifyPipe,
    LoadingComponent
  ],
  exports: [
    TranslateModule,
    // HttpClientModule,
    UtilsModule,
    MatNativeDateModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatListModule,
    MatGridListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSliderModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatBadgeModule,
    FlexLayoutModule,
    NgxPaginationModule,
    SafeHtmlPipe,
    FileSizePipe,
    TruncatePipe,
    BaseWebComponent,
    SectionTitleComponent,
    SectionTitleDinamicComponent,
    BaseListWebComponent,
    AttachmentsComponent,
    ImagesModuleComponent,
    ImageGalleryComponent,
    AttachmentsModuleComponent,
    VideosModuleComponent,
    VideoDialogComponent,
    CarouselModule,
    LoadingButtonComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    CalendarComponent,
    MatMomentDateModule,
    PurchaseComponent,
    AttachmentsHandleComponent,
    EditAttachmentComponent,
    FileInputComponent,
    ShareToolbarComponent,
    AddThisComponent,
    SlugifyPipe,
    LoadingComponent
  ],
  entryComponents: [
    ImageGalleryComponent,
    VideoDialogComponent,
    EditAttachmentComponent
  ],
  providers: [
    BreakpointObserver,
    MediaMatcher,
    ImageGalleryService,
    EmbedVideoService,
    SlugifyPipe,
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'D/MM/YYYY'
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM Y',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM Y'
        }
      }
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: RECAPTCHA_KEY } as RecaptchaSettings,
    },
  ],
})

export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [SharedService]
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
