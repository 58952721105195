export function preparePagination(values: any, paginate?: boolean) {
  const pagination = {
    params: {
      paginate: paginate ? 'true' : 'false',
      page: values && paginate ? values['page'] : '0',
      quantity: values && paginate ? values['quantity'] : '0'
    }
  };
  const val = Object.assign({}, values);
  delete val['page'];
  delete val['quantity'];

  return { values: val, pagination };
}

export function isImage(ext: string): boolean {
  switch (ext) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return true;
    default:
      return false;
  }
}

function calcDCPartial(string: any) {
  let dcPartial = 0;
  const table = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
  let sum = 0;
  for (let i = 0; i < string.length; i++) {
    sum = sum + string.charAt(string.length - 1 - i) * table[i];
  }
  dcPartial = (11 - (sum % 11));
  if (dcPartial === 11) {
    dcPartial = 0;
  } else if (dcPartial === 10) {
    dcPartial = 1;
  }
  return dcPartial.toString();
}

export function checkCCC(cccNumber) {
  let entity = cccNumber.substring(0, 4);
  let branch = cccNumber.substring(4, 8);
  let dc = cccNumber.substring(8, 10);
  let numberC = cccNumber.substring(10, 20);
  entity = completeZeros(entity, 4);
  branch = completeZeros(branch, 4);
  dc = completeZeros(dc, 2);
  numberC = completeZeros(numberC, 10);

  const concat = entity + branch;
  const dc1 = calcDCPartial(concat);
  const dc2 = calcDCPartial(numberC);

  return cccNumber && (dc === (dc1 + dc2));
}

function completeZeros(number, long) {
  let ceros = '';
  number = number.toString();
  for (let i = 0; (long - number.length) > i; i++) {
    ceros += '0';
  }
  return ceros + number;
}

export function calculaIBAN(cccNumber) {
  const codCountry = 'ES';
  const entity = cccNumber.substring(0, 4);
  const branch = cccNumber.substring(4, 8);
  const dc = cccNumber.substring(8, 10);
  const numberC = cccNumber.substring(10, 20);
  const preIban = entity +
    branch +
    dc +
    numberC +
    getCharIBAN(codCountry.charAt(0)) +
    getCharIBAN(codCountry.charAt(1)) + '00';
  let dcIban: any = 98 - modulo(preIban, 97);
  dcIban = completeZeros(dcIban, 2);
  return codCountry + dcIban + entity + branch + dc + numberC;
}

export function setContrast(color) {
  const c = color.substring(1);      // strip #
  const rgb = parseInt(c, 16);   // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff;  // extract red
  const g = (rgb >> 8) & 0xff;  // extract green
  const b = (rgb >> 0) & 0xff;  // extract blue
  const o = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  const contrast_color = (o > 125) ? '#333' : '#fff';

  return contrast_color;
}

function modulo(preIBAN, divisor) {

  let rest = 0;
  for (let i = 0; i < preIBAN.length; i += 10) {
    const dib: any = rest + '' + preIBAN.substr(i, 10);
    rest = dib % divisor;
  }

  return rest;
}

function getCharIBAN(character) {
  let peso = '';
  character = character.toUpperCase();
  switch (character) {
    case 'A':
      peso = '10';
      break;
    case 'B':
      peso = '11';
      break;
    case 'C':
      peso = '12';
      break;
    case 'D':
      peso = '13';
      break;
    case 'E':
      peso = '14';
      break;
    case 'F':
      peso = '15';
      break;
    case 'G':
      peso = '16';
      break;
    case 'H':
      peso = '17';
      break;
    case 'I':
      peso = '18';
      break;
    case 'J':
      peso = '19';
      break;
    case 'K':
      peso = '20';
      break;
    case 'L':
      peso = '21';
      break;
    case 'M':
      peso = '22';
      break;
    case 'N':
      peso = '23';
      break;
    case 'O':
      peso = '24';
      break;
    case 'P':
      peso = '25';
      break;
    case 'Q':
      peso = '26';
      break;
    case 'R':
      peso = '27';
      break;
    case 'S':
      peso = '28';
      break;
    case 'T':
      peso = '29';
      break;
    case 'U':
      peso = '30';
      break;
    case 'V':
      peso = '31';
      break;
    case 'W':
      peso = '32';
      break;
    case 'X':
      peso = '33';
      break;
    case 'Y':
      peso = '34';
      break;
    case 'Z':
      peso = '35';
      break;
  }
  return peso;
}

/*
@Injectable()
export class CustomPaginator extends MatPaginatorIntl {

  paginatorLang = {
    'of': 'de',
    'itemsPerPageLabel': 'Registros por página: ',
    'nextPageLabel': 'Página siguiente',
    'previousPageLabel': 'Página anterior'
  };

  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;

  constructor(private translate: TranslateService) {
    super();

    this.translate.setDefaultLang('es');

    this.translate.get('core.paginator').subscribe(res => {
      this.paginatorLang = res;

      this.itemsPerPageLabel = res.itemsPerPageLabel;
      this.nextPageLabel = res.nextPageLabel;
      this.previousPageLabel = res.previousPageLabel;

      this.changes.next();
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {

    if (length === 0 || pageSize === 0) {
      return `0 ${this.paginatorLang.of} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.paginatorLang.of} ${length}`;
  }

}
*/

export function getCookie(cookieName: string) {
  let keyValue;
  keyValue = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)');
  return keyValue ? keyValue[2] : null;
}

export function setCookie(cookieName: string, value: string, days: number, domain?: string) {
  const expires = new Date();
  expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = cookieName + '=' + value + ';expires=' + expires.toUTCString() + ';path=/;' + (domain ? ';Domain=domain' : null);
}

export function deleteCookie(cookieName: string, domain?: string) {
  document.cookie = cookieName + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;' + (domain ? ';Domain=domain' : null);
}


export function colorLuminance(hex, lum) {
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  var rgb = "#", c, i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

export function invertColor(hex: string, bw?) {
  if (hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#333333' : '#FFFFFF';
    }
    // invert color components
    var _r = (255 - r).toString(16);
    var _g = (255 - g).toString(16);
    var _b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(_r) + padZero(_g) + padZero(_b);
  } else {
    return null;
  }
}

export function padZero(str, len?) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}