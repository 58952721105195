import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class EmbedVideoService {

    constructor(
        private http: HttpClient,
    ) { }

    public embed_video(url_video: string) {
        let id;
        const url = new URL(url_video);

        // Youtube
        id = this.detectYoutube(url);
        if (id) {
            return 'https://www.youtube.com/embed/' + id;
        }

        // Vimeo
        id = this.detectVimeo(url);
        if (id) {
            return 'https://player.vimeo.com/video/' + id;
        }

        // Dailymotion
        id = this.detectDailymotion(url);
        if (id) {
            return 'https://www.dailymotion.com/embed/video/' + id;
        }

        return '';
    }

    private detectYoutube(url: any): string {
        if (url.hostname.indexOf('youtube.com') > -1) {
            return url.search.split('=')[1];
        }

        if (url.hostname === 'youtu.be') {
            return url.pathname.split('/')[1];
        }

        return '';
    }

    private detectVimeo(url: any): string {
        return (url.hostname === 'vimeo.com') ? url.pathname.split('/')[1] : null;
    }

    private detectDailymotion(url: any): string {
        if (url.hostname.indexOf('dailymotion.com') > -1) {
            return url.pathname.split('/')[2].split('_')[0];
        }

        if (url.hostname === 'dai.ly') {
            return url.pathname.split('/')[1];
        }

        return '';
    }

    public url_image(url: any): any {
        let id;

        url = new URL(url);

        id = this.detectYoutube(url);
        if (id) {
            return this.embed_youtube_image(id);
        }

        id = this.detectVimeo(url);
        if (id) {
            return this.embed_vimeo_image(id);
        }

        id = this.detectDailymotion(url);
        if (id) {
            return this.embed_dailymotion_image(id);
        }

        return Promise.resolve();
    }

    private embed_youtube_image(id: string): any {
        const result = 'https://img.youtube.com/vi/' + id + '/default.jpg';
        return new Promise((resolve) => {
            resolve(result);
        });
    }

    private embed_vimeo_image(id: string): any {
        return new Promise(
            (resolve, reject) => {
                this.http.get('https://vimeo.com/api/v2/video/' + id + '.json').subscribe((res: any) => {
                    resolve(res[0]['thumbnail_large']);
                },
                    e => reject(e));
            });
    }

    private embed_dailymotion_image(id: string): any {
        return this.http.get('https://api.dailymotion.com/video/' + id + '?fields=thumbnail_480_url')
            .pipe(map((res: any) => {
                return res['thumbnail_480_url'];
            }))
            .toPromise()
            .catch(error => console.log(error));
    }
}
