import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../environments/environment';

export class TranslationsLoader implements TranslateLoader {

  constructor(private http: HttpClient) {
  }

  public getTranslation(lang: string): Observable<any> {
    if (!environment.production) {
      return this.http.get(`./assets/i18n/${lang}.json`).pipe(shareReplay(1));
    } else {
      return this.http.get(`${environment.ssrHost}/assets/i18n/${lang}.json`).pipe(shareReplay(1));
    }
  }
}

export function TranslationsLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslationsLoader(http);
}
