import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseListWebService } from '@web/base/shared/components/base-list-web/base-list-web.service';
import { URL } from '../../../../environments/environment';
import { preparePagination } from '@web/base/shared/utils';

@Injectable()
export class ContentsService extends BaseListWebService {

  constructor(public http: HttpClient) {
    super(http);
  }

  getCategory(categoryID) {
    const url = `${URL}/contents-categories/${categoryID}`;
    return this.http.get(url);
  }

  getContent(contentID) {
    const url = `${URL}/contents/${contentID}`;
    return this.http.get(url);
  }

  loadMore(sectionID) {
    const url = `${URL}/contents-more/find`;
    const values = preparePagination({sectionID});
    return this.http.post(url, values.values, values.pagination);
  }

  print(contentID) {
    const paramsPDF = new HttpParams().set('format', 'pdf').set('contentType', 'application/pdf').set('filename', '');
    const url = `${URL}/contents/report`;
    return this.http.post(url, { contentID }, { responseType: 'blob', observe: 'response', params: paramsPDF });
  }
}
