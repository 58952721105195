import { Constants } from '@web/base/shared/constants.class';
import { MenuItem } from '@web/base/core/menu/menu-item.model';

export class ConstantsProject extends Constants {

    static COOKIES_CONSENT = '__CositalCookiesConsent';

    static PROJECT_LOCATION = { lat: 40.415546, lng: -3.703524 };

    static MENU_SIDENAV: Array<MenuItem> = [];

    static MENU_HEADER: Array<MenuItem> = [
        {
            title: 'information', url: '',
            children: [
                { title: 'presentation', url: '/p/11/proyecto-esperanto' },
                { title: 'collaborators', url: '/p/38/colaboradores' },
                { title: 'sponsors', url: '/p/39/patrocinadores', },
                { title: 'subscriptions', url: '/informacion/suscripciones' },
                { title: 'provincialCouncils', url: '/p/40/diputaciones-provinciales' },
                { title: 'externalServices', url: '/p/41/servicios-externos' },
                { title: 'contact', url: '/informacion/contacto' }
            ]
        },
        {
            title: 'protocols',
            url: '',
            children: [
                { title: 'presentation', url: '/p/42/presentacion-de-protocolos' },
                { title: 'bullying', url: '/contenidos/2/protocolo-de-acoso-laboral' },
                // { title: 'managementTools', url: '/protocolos/herramientas-de-management' },
                {
                    title: 'circulars',
                    url: '',
                    children: [
                        { title: 'presentation', url: '/p/43/circulares-presentacion' },
                        { title: 'mixed', url: '/contenidos/3/circulares-mixtas' },
                        { title: 'secretary', url: '/contenidos/24/circulares-secretaria' },
                        { title: 'intervention', url: '/contenidos/25/circulares-intervencion' },
                        { title: 'treasury', url: '/contenidos/26/circulares-tesoreria' },
                    ]
                }
            ]
        },
        {
            title: 'observatories',
            url: '',
            children: [
                { title: 'presentation', url: '/p/44/observatorios-presentacion' },
                // De forma dinámica se añaden los observatorios
                // { title: 'observatories', url: '' },
            ]
        },
        {
            title: 'models',
            children: [
                { title: 'modelObservatory2', url: 'observatorios/2/contenidos/675/modelos' },
                { title: 'modelObservatory11', url: 'portales/11/portal-de-control-interno/contenidos/7050/modelos' },
                { title: 'modelObservatory3', url: 'observatorios/3/contenidos/6121/modelos-endeudamiento' },
                { title: 'modelObservatory4', url: 'observatorios/4/contenidos/645/modelos-estabilidad-presupuestaria' },
                { title: 'modelObservatory5', url: 'observatorios/5/contenidos/680/modelos-recaudacion' },
                { title: 'modelObservatory5.1', url: 'observatorios/5/contenidos/6910/modelos-recaudacion' },
                { title: 'modelObservatory8', url: 'observatorios/8/contenidos/678/guias-y-modelos-adaptados-ley-27-2013' },
                { title: 'modelObservatory12', url: 'portales/12/portal-de-tesoreria/contenidos/6729/modelos-tesoreria' }
            ]
        },
        {
            title: 'queries',
            children: [
                { title: 'presentation', url: '/consultas/presentacion' }
            ]
        },
        {
            title: 'portals',
            children: [
                // De forma dinámica se añaden los portales
                // { title: 'portals', url: '' },
            ]
        },
        {
            title: 'education',
            children: [
              // {
              //   title: 'descentralized',
              //   url: '',
              //   children: [
              //       { title: 'descentralized_2020', url: '/contenidos/7301/formacion-descentralizada-2020' },
              //       { title: 'descentralized_2019', url: '/contenidos/7038/formacion-descentralizada-2019' },
              //       { title: 'descentralized_2018', url: '/contenidos/7037/formacion-descentralizada-2018' }
              //   ]
              // },
              { title: 'online', url: '/cursos/1/online' },
              { title: 'faceToFace', url: '/cursos/2/jornadas' },
              { title: 'descentralized', url: '/cursos/3/descentralizada' },
            ]
        },
        { title: 'campus', url: 'https://campus.cositalnetwork.es/', external: true }
    ];

    constructor() {
        super();
    }
}
