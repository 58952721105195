import { SharedService } from '@web/base/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '@web/base/auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseWebComponent } from '@web/base/shared/components/base-web/base-web.component';
import { checkForm } from '../../../../../../../../../libraries/utils/src/lib/utils';
import { IResponse } from '@web/base/shared/models/response.model';

@Component({
  selector: 'web-collegiate-update',
  templateUrl: './collegiate-update.component.html',
  styleUrls: ['./collegiate-update.component.scss'],
})
export class CollegiateUpdateComponent extends BaseWebComponent implements OnInit {

  public form: FormGroup;
  public observatories = [];
  public provinces = [];

  constructor(
    translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CollegiateUpdateComponent>,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public router: Router,
    public authService: AuthService
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  ngOnInit() {
    this.createForm();

    this.sharedService.loadObservatories(1).subscribe((res: IResponse) => {
      this.observatories = res.result.items;
    });

    // María. 24/11/2023. Según me comenta María de cosital Ceuta y Melilla no tienen colegio territorial
    this.sharedService.loadProvinces().subscribe((res: IResponse) => {
      this.provinces = res.result.items.filter(p => p.name !== 'CEUTA' && p.name !== 'MELILLA');
    });
  }

  createForm() {
    this.form = new FormGroup({
      fhn: new FormControl(1),
      isCollegiateUpdate: new FormControl(null, [Validators.required]),
      schoolCollegiate: new FormControl(null),
      observatoryID: new FormControl(null),
    });

    this.form.controls['isCollegiateUpdate'].valueChanges.subscribe(v => {
      if (v === 0) {
        // this.form.controls['observatoryID'].setValidators([Validators.required]);
        this.form.controls['schoolCollegiate'].setValidators(null);
        this.form.controls['schoolCollegiate'].setValue(null);
      } else {
        this.form.controls['observatoryID'].setValidators(null);
        this.form.controls['observatoryID'].setValue(null);
        if (v === 1) {
          this.form.controls['schoolCollegiate'].setValidators([Validators.required]);
        }
      }
    });
  }

  close(value?) {
    this.dialogRef.close(value);
  }

  submit() {

    checkForm(this.form);

    if (this.form.valid) {
      this.close(this.form.value);
    } else {
      this.close();
    }
  }

}
