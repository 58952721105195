import { SigninService } from './../../core/header/dialogs/signin/signin.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';

import * as AuthActions from './auth.actions';
import { UserLogin } from './../user-login.model';
import { UserAWS } from './../user-aws.model';
import { URL } from '../../../../environments/environment';

import { map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { CoreService } from '@web/base/core/core.service';
import { Template } from '@web/base/core/template/template.model';

@Injectable()
export class AuthEffects {

  @Effect()
  signin = this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNIN),
      map((action: AuthActions.TrySignin) => {
        return action.payload;
      }),
      switchMap((authData: UserLogin) => {
        const url = `${URL}/users-web/login`;
        return this.http.post(url, { username: authData.username, password: authData.password });
        // .subscribe(() => { }, (error: any) => {
        //   this.authService.errorLogin = error.error.msg;
        //   return [];
        // });
      }),
      mergeMap((data: { msg: string, result: UserAWS }) => {

        localStorage.setItem(ConstantsProject.TOKEN_KEY, data.result.token);

        // get return url from route parameters or default reload page
        if (this.route.snapshot.queryParams['returnUrl']) {
          /*
          this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
          this.coreService.templateLoaded$.subscribe((res: Template) => {
            if (res.result.user) {
              this.signinService.closeDialog();
            }
          });
          this.coreService.loadTemplate();
          */
          const subscription = this.authService.isLoggedIn().subscribe(res => {
            if (res) {
              // this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
              window.location.href = this.route.snapshot.queryParams['returnUrl'];
              subscription.unsubscribe();
            }
          });

          this.signinService.closeDialog();
          this.coreService.loadTemplate();
        } else {
          // this.coreService.templateLoaded$.subscribe((res: Template) => {
          //   if (res.result.user) {
          //     this.signinService.closeDialog();
          //   }
          // });
          this.signinService.closeDialog();
          this.coreService.loadTemplate();
        }

        return [
          {
            type: AuthActions.SIGNIN
          },
          {
            type: AuthActions.SET_TOKEN,
            payload: data.result.token
          }
        ];
      }),
      catchError((error: any, caught) => {
        if (error instanceof HttpErrorResponse && error.error.result) {
          this.authService.errorLogin = error.error.result.message;
        }
        // return throwError(error);
        return caught;
      })
    );

  @Effect()
  signinByCodeAndHash = this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNIN_BY_CODE_AND_HASH),
      map((action: AuthActions.TrySigninByCodeAndHash) => {
        return { code: action.code, hash: action.hash };
      }),
      switchMap((authData: { code: string, hash: string }) => {
        const url = `${URL}/users-web/login-by-code-and-hash`;

        return this.http.post(url, authData);
        // .catch((error: any) => {
        //   this.authService.errorLogin = error.error.msg;
        //   return [];
        // });
      }),
      mergeMap((data: { msg: string, result: UserAWS }) => {
        localStorage.setItem(ConstantsProject.TOKEN_KEY, data.result.token);

        // get return url from route parameters or default reload page

        if (this.route.snapshot.queryParams['returnUrl']) {
          this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
          this.signinService.closeDialog();
        } else {
          window.location.reload();
        }

        return [
          {
            type: AuthActions.SIGNIN
          },
          {
            type: AuthActions.SET_TOKEN,
            payload: data.result.token
          }
        ];
      })
    );

  constructor(private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private signinService: SigninService,
    private authService: AuthService,
    private coreService: CoreService,
  ) { }
}
