import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent {

  constructor(translate: TranslateService, public router: Router) {  }

  @Input() title: string;
  @Input() subtitle: string;
  @Input() private: boolean;
  @Input() back: boolean;
  @Input() backUrl: string;
 
  goBack() {
    if(this.backUrl) {
      this.router.navigateByUrl(this.backUrl);
    }
  }
}
