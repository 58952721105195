import { CookiesConsentComponent } from './cookies/cookies-consent/cookies-consent.component';
import { GridContentComponent } from './home/grid-content/grid-content.component';
import { SliderContentComponent } from './home/slider-content/slider-content.component';
import { BannersSidenavComponent } from './banners/banners-sidenav/banners-sidenav.component';
import { BannersHeaderComponent } from './banners/banners-header/banners-header.component';
import { BannersFooterComponent } from './banners/banners-footer/banners-footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HandleHttpResponseInterceptor } from '../shared/interceptors/handle-http-response.interceptor';
import { HandleHttpRequestInterceptor } from '../shared/interceptors/handle-http-request.interceptor';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TemplateComponent } from './template/template.component';
import { Error404Component } from './error-404/error-404.component';
import { Error403Component } from './error-403/error-403.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CookiesComponent } from './cookies/cookies.component';
import { RememberPasswordComponent } from './header/dialogs/remember-password/remember-password.component';
import { SigninComponent } from './header/dialogs/signin/signin.component';
import { CoreService } from './core.service';
import { SigninService } from './header/dialogs/signin/signin.service';
import { RememberPasswordService } from './header/dialogs/remember-password/remember-password.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '../auth/auth.module';
import { CoreRoutingModule } from '@web/project/core/core-routing.module';
import { HomeComponent } from './home/home.component';
import { MenuSidenavComponent } from './menu/menu-sidenav/menu-sidenav.component';
import { MenuMobileComponent } from './menu/menu-mobile/menu-mobile.component';
import { MenuHeaderComponent } from './menu/menu-header/menu-header.component';
import { MenuMobileItemComponent } from './menu/menu-mobile/menu-mobile-item/menu-mobile-item.component';
import { MenuSidenavItemComponent } from './menu/menu-sidenav/menu-sidenav-item/menu-sidenav-item.component';
import { MenuHeaderItemComponent } from './menu/menu-header/menu-header-item/menu-header-item.component';
import { MenuService } from './menu/menu.service';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { ListContentComponent } from './home/list-content/list-content.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderService } from './header/header.service';
import { CollegiateUpdateComponent } from './template/dialogs/collegiate-update/collegiate-update.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule.forRoot(),
    AuthModule,
    RouterModule,
    HttpClientModule,
    CoreRoutingModule,
  ],
  exports: [
    HeaderComponent,
    RememberPasswordComponent,
    SigninComponent,
    FooterComponent,
    CookiesComponent,
    TemplateComponent,
    Error404Component,
    Error403Component,
    HomeComponent,
    MenuSidenavComponent,
    MenuMobileComponent,
    MenuHeaderComponent,
    MenuMobileItemComponent,
    MenuSidenavItemComponent,
    MenuHeaderItemComponent,
    BreadcrumbComponent,
    BannersFooterComponent,
    BannersHeaderComponent,
    BannersSidenavComponent,
    SidenavComponent,
    CollegiateUpdateComponent
  ],
  declarations: [
    HeaderComponent,
    RememberPasswordComponent,
    SigninComponent,
    FooterComponent,
    CookiesComponent,
    CookiesConsentComponent,
    TemplateComponent,
    Error404Component,
    Error403Component,
    HomeComponent,
    MenuSidenavComponent,
    MenuMobileComponent,
    MenuHeaderComponent,
    MenuMobileItemComponent,
    MenuSidenavItemComponent,
    MenuHeaderItemComponent,
    BreadcrumbComponent,
    PopupDialogComponent,
    BannersFooterComponent,
    BannersHeaderComponent,
    BannersSidenavComponent,
    SliderContentComponent,
    GridContentComponent,
    ListContentComponent,
    SidenavComponent,
    CollegiateUpdateComponent
  ],
  entryComponents: [
    SigninComponent,
    RememberPasswordComponent,
    PopupDialogComponent,
    CollegiateUpdateComponent
  ],
  providers: [
    CoreService,
    SigninService,
    RememberPasswordService,
    MenuService,
    HeaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleHttpResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleHttpRequestInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
