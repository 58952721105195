import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";

import { Template } from "./template/template.model";
import { URL } from "../../../environments/environment";
import { ICalendarItem } from '../shared/components/calendar/calendar-item.model';

@Injectable()
export class CoreService {
  // Banners -----------
  // private bannersHeader = new BehaviorSubject<any>(null);
  // private bannersFooter = new BehaviorSubject<any>(null);
  private bannersSidenav = new BehaviorSubject<any>(null);
  // bannersHeader$ = this.bannersHeader.asObservable();
  // bannersFooter$ = this.bannersFooter.asObservable();
  bannersSidenav$ = this.bannersSidenav.asObservable();
  //-------------

  events = new Subject<ICalendarItem[]>();
  eventsLoaded$ = this.events.asObservable();

  sidenavStatus$ = new Subject<boolean>();
  isMobile$ = new BehaviorSubject<boolean>(false);

  // private template = new Subject<Template>();
  private template = new BehaviorSubject<Template>(null);
  templateLoaded$ = this.template.asObservable();

  constructor(private http: HttpClient) { }

  loadTemplate() {
    const url = `${URL}/template`;
    return this.http.get(url).subscribe((data: Template) => {
      return this.template.next(data);
    });
  }

  loadCover() {
    const url = `${URL}/cover`;
    return this.http.get(url);
  }

  loadEventsByMonthAndYear(month: number, year: number) {
    const url = `${URL}/calendar/${month}/${year}`;
    return this.http.get(url);
  }

  getSidenavStatus() {
    return this.sidenavStatus$;
  }

  setSidenavStatus(status) {
    this.sidenavStatus$.next(status);
  }

  isMobile() {
    return this.isMobile;
  }

  setIsMobile(val) {
    this.isMobile$.next(val);
  }

  // setBannersHeader(banners) {
  //   console.log('banners header', banners);
  //   this.bannersHeader.next(banners);
  // }

  // setBannersFooter(banners) {
  //   console.log('banners footer', banners);
  //   this.bannersFooter.next(banners);
  // }

  setBannersSidenav(banners) {
    // console.log('banners side -> ', banners);
    this.bannersSidenav.next(banners);
  }
}
