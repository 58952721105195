import { ConstantsProject } from '@web/project/shared/constants.class';
import { getCookie } from '@web/base/shared/utils';
import { publicS3Url } from './../../../../../environments/environment';
import { SharedService } from '@web/base/shared/shared.service';
import { BaseComponent } from 'utils';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Component, ViewChild, ElementRef, AfterViewInit, Renderer2, Input, OnInit } from '@angular/core';

@Component({
  selector: 'web-addthis',
  templateUrl: './addthis.component.html',
  styleUrls: ['./addthis.component.scss'],
})
export class AddThisComponent extends BaseComponent implements OnInit, AfterViewInit {

  showSidenav = false;

  @Input('image') contentImage: string;
  @Input('title') contentTitle: string;
  @Input('description') contentDescription: string;

  @ViewChild('addThisScriptContainer', { static: false }) addThisScriptContainer: ElementRef;
  @ViewChild('addThisDiv', { static: false }) addThisDiv: ElementRef;
  addThisUrl = 'cositalnetwork.es';

  // Cookies
  private cookiesConsent;
  public functionalCookies = false;

  private imageWidth = 600;
  private imageHeight = 480;

  // private FACEBOOK_APP_ID = '249499007347285';  // Provisional, usando cuenta de desarrollador de Edu

  constructor(
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    private renderer2: Renderer2,
  ) {
    super(translate, snackbar, dialog);
  }

  ngOnInit() {
    // super.ngOnInit();

    // Comprobar cookies para mostrar ciertos contenidos
    this.cookiesConsent = JSON.parse(getCookie(ConstantsProject.COOKIES_CONSENT));
    if (this.cookiesConsent) {
      this.functionalCookies = this.cookiesConsent.functionals;
    }

    if (this.contentTitle.length > 80) {
      this.contentTitle = this.contentTitle.substr(0, 80);
      this.contentTitle = this.contentTitle.substr(0, Math.min(this.contentTitle.length, this.contentTitle.lastIndexOf(' ')));
      this.contentTitle = this.contentTitle + '...';
    }
    this.contentDescription = this.contentDescription ? this.contentDescription : this.contentTitle;
    // this.contentImage = this.contentImage ? this.contentImage : publicS3Url+'logo_cosital_network.jpg';
    this.contentImage = this.contentImage ? this.contentImage :  './../../../../../assets/img/project/logo_redes_sociales.jpg';

    // Metas OG (medidas imagen) ---------
    var img = new Image();
    img.onload = () => {
      this.imageWidth = img.width;
      this.imageHeight = img.height;
      this.meta.updateTag({ property: 'og:image:width', content: ''+this.imageWidth });
      this.meta.updateTag({ property: 'og:image:height', content: ''+this.imageHeight });
    };
    img.src = this.contentImage;
    //---------

   this.addThisUrl = window.location.href;

    // console.log(this.contentImage);
    // console.log(this.contentTitle);
    // console.log(this.contentDescription);
    // console.log(this.addThisUrl);
  }

  ngAfterViewInit() {
    // this.addThisDiv.nativeElement.setAttribute('addthis:title', this.contentTitle);
    const addThisScriptContainer = this.addThisScriptContainer.nativeElement;

    // Metas OG ---------
    // this.meta.updateTag({ property: 'fb:app_id', content: this.FACEBOOK_APP_ID });
    this.meta.updateTag({ property: 'og:image', content: this.contentImage });
    this.meta.updateTag({ property: 'og:image:secure_url', content: this.contentImage });
    this.meta.updateTag({ property: 'og:image:alt', content: this.contentTitle });
    // this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    // this.meta.updateTag({ property: 'og:image:width', content: '300'});
    // this.meta.updateTag({ property: 'og:image:height', content: '200' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Cosital Network' });
    this.meta.updateTag({ property: 'og:url', content: this.addThisUrl });
    this.meta.updateTag({ property: 'og:title', content: this.contentTitle });
    this.meta.updateTag({ property: 'og:description', content: this.contentDescription });

    // Metas generales ---------
    this.meta.updateTag({ name: 'title', content: this.contentTitle });
    this.meta.updateTag({ name: 'description', content: this.contentDescription });
    this.meta.updateTag({ name: 'image', content: this.contentImage });

    // Metas de twitter ---------
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: '@cosital' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@cosital' });
    this.meta.updateTag({ name: 'twitter:title', content: this.contentTitle });
    this.meta.updateTag({ name: 'twitter:description', content: this.contentDescription });
    this.meta.updateTag({ name: 'twitter:text:summary_photo_image_alt_text', content: this.contentTitle });
    this.meta.updateTag({ name: 'twitter:image', content: this.contentImage });
    this.meta.updateTag({ name: 'twitter:image:src', content: this.contentImage });

    if (this.functionalCookies) {
      setTimeout(() => {
        // window['addthis_config'] = {
        //   'data_track_addressbar': false,
        //   "data_track_clickback": false,
        //   'pubid': 'ra-601a44595cb01bca'
        // };

        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        // s.src = 'https://s7.addthis.com/js/300/addthis_widget.js';
        s.src = 'https://static.addtoany.com/menu/page.js';
        this.renderer2.appendChild(addThisScriptContainer, s);
        // if ((<any>window).addthis) {
        //   window['addthis_share'].url = this.addThisUrl;
        //   window['addthis_share'].title = this.contentTitle;
        //   window['addthis_share'].description = this.contentDescription;
        //   window['addthis_share'].media = this.contentImage;
        //   (<any>window).addthis.toolbox('.addthis_toolbox');
        // }
      });
    }
  }
}
