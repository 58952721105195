import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SimpleSnackbarComponent, BaseComponent } from 'utils';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss']
})
export class RememberPasswordComponent extends BaseComponent implements OnInit {

  public form: FormGroup;

  constructor(
    translate: TranslateService,
    snackbar: MatSnackBar,
    dialog: MatDialog,
    private dialogRef: MatDialogRef<RememberPasswordComponent>,
    private authService: AuthService,
  ) {
    super(translate, snackbar, dialog);
  }

  ngOnInit() {

    this.getTranslations(['core']);

    this.form = new FormGroup({
      username: new FormControl(null),
      email: new FormControl(null)
    });
  }

  rememberPass() {
    if ((this.form.controls['username'].value === null || this.form.controls['username'].value === '') && (this.form.controls['email'].value === null || this.form.controls['email'].value === '')) {
      this.openSnackbar(this.translations['core']['signin'].needed, SimpleSnackbarComponent.TYPE_ERROR);
    } else  if (this.form.controls['username'].value !== null && this.form.controls['username'].value !== '' && this.form.controls['email'].value !== null && this.form.controls['email'].value !== '') {
      this.openSnackbar(this.translations['core']['signin'].onlyOne, SimpleSnackbarComponent.TYPE_ERROR);
    } else {
      this.subscriptions.push(this.authService.rememberPass(this.form.value).subscribe(
        () => {
          this.openSnackbar(this.translations['core']['signin'].rememberPasswordOK, SimpleSnackbarComponent.TYPE_OK);

          this.dialogRef.close();

          this.form.reset();
        },
        error => {
          this.openSnackbar(error.error.result.message, SimpleSnackbarComponent.TYPE_ERROR);
        }
      )
      );
    }
  }

}
