import { ConstantsProject } from '@web/project/shared/constants.class';
import { getCookie } from '@web/base/shared/utils';
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialog, MatGridList } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { IVideo } from './video.model';
import { EmbedVideoService } from './embed-video-service';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { order } from 'utils';

@Component({
  selector: 'web-videos-module',
  templateUrl: './videos-module.component.html',
  styleUrls: ['./videos-module.component.scss']
})
export class VideosModuleComponent implements OnInit, AfterViewInit {

  @ViewChild('videoGallery', { static: false }) videoGallery: ElementRef;
  @ViewChild('grid', { static: false }) grid: MatGridList;
  public galleryColumns: number;
  public innerWidth: number;

  @Input() videos: IVideo[];
  @Input() title: string;
  @Input() order: 'asc' | 'desc' = 'asc';

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.recalculateGrid();
  }

  private cookiesConsent;
  public functionalCookies = false;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public embedVideoService: EmbedVideoService
  ) { }

  ngOnInit() {
    if (this.order === 'asc') {
      this.videos.sort((a, b) => order(a, b));
    } else {
      this.videos.sort((a, b) => order(a, b, 'order', 'desc'));
    }

    this.videos.map(async (v, i) => {
      this.embedVideoService.url_image(v.videoUrl)
        .then(thumbnail => {
          this.videos[i].thumbnail = thumbnail;
        });
    });

    // Comprobar cookies para mostrar ciertos contenidos
    this.cookiesConsent = JSON.parse(getCookie(ConstantsProject.COOKIES_CONSENT));
    if (this.cookiesConsent) {
      this.functionalCookies = this.cookiesConsent.functionals;
    }
  }

  ngAfterViewInit() {
    this.recalculateGrid();
  }

  recalculateGrid() {
    this.innerWidth = +<ElementRef>(this.videoGallery).nativeElement.clientWidth;
    if (this.innerWidth < 300) {
      this.galleryColumns = 2;
    } else if (this.innerWidth >= 300 && this.innerWidth < 500) {
      this.galleryColumns = 4;
    } else {
      this.galleryColumns = 8;
    }

    this.grid.cols = this.galleryColumns;
  }

  loadVideo(video: IVideo) {
    if (this.functionalCookies) {
      this.dialog.open(VideoDialogComponent, {
        panelClass: ['dialog', 'dialog-sm', 'video-dialog'],
        data: {
          title: video.title,
          video: this.sanitizer.bypassSecurityTrustResourceUrl(this.embedVideoService.embed_video(video.videoUrl))
        },
        width: '75%'
      });
    }
  }

}
