import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Injectable } from '@angular/core';

import { URL } from '../../../../environments/environment';

@Injectable()
export class HandleHttpRequestInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let changedReq;

    // Si se trata de una llamada a la API, añadimos nuestras cabeceras; en caso contrario, no.
    if (req.url.substr(0, URL.length) !== URL) {
      changedReq = req.clone();
    } else {
      changedReq = req.clone({
        headers: req.headers
          .set('langID', '1')
      });

      if (localStorage.getItem(ConstantsProject.TOKEN_KEY) && !changedReq.headers.has('skip-auth')) {
        changedReq = changedReq.clone({
          headers: changedReq.headers
            .set('Authorization', localStorage.getItem(ConstantsProject.TOKEN_KEY))
        });
      }

      if (changedReq.method === 'GET') {
        changedReq = changedReq.clone({
          headers: changedReq.headers.set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
        });
      }
    }
    return next.handle(changedReq);
  }
}
