export class IBAN {
    iban = '';
    field1 = '';
    field2 = '';
    field3 = '';
    field4 = '';
    field5 = '';

    constructor(value?: string | {
        iban: string,
        field1: string,
        field2: string,
        field3: string,
        field4: string,
        field5: string
    }) {
        value = value ? value : '';
        if (typeof value === 'string') {
            value = value.toUpperCase();
            if (value.substr(0, 2) === 'ES') {
                this.iban = value.substr(0, 4);
                this.field1 = value.substr(4, 4);
                this.field2 = value.substr(8, 4);
                this.field3 = value.substr(12, 4);
                this.field4 = value.substr(16, 4);
                this.field5 = value.substr(20, 4);
            } else {
                this.iban = value;
            }
        } else {
            this.iban = value.iban;
            this.field1 = value.field1;
            this.field2 = value.field2;
            this.field3 = value.field3;
            this.field4 = value.field4;
            this.field5 = value.field5;
        }
    }

    toString() {
        if (this.iban && this.iban.substr(0, 2) === 'ES') {
            return this.iban + this.field1 + this.field2 + this.field3 + this.field4 + this.field5;
        } else {
            return this.iban;
        }
    }

    isCompleted() {
        if (this.iban.substr(0, 2) === 'ES') {
            return this.field1
                && this.field1.length > 3
                && this.field2
                && this.field2.length > 3
                && this.field3
                && this.field3.length > 3
                && this.field4
                && this.field4.length > 3
                && this.field5
                && this.field5.length > 3;
        } else {
            // TODO -- La longitud correcta dependerá del país
            return this.iban.length === 32;
        }
    }
}
