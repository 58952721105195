import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '@web/base/shared/shared.service';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component } from '@angular/core';
import { IBreadCrumb } from '../../models/breadcrumb.model';

@Component({
  template: ''
})
export class BaseWebComponent extends BaseComponent implements OnInit {

  public section: string;
  public sectionTitle: string;
  public showSidenav = true;

  public breadcrumbs: Array<IBreadCrumb> = [];

  constructor(
    public translate: TranslateService,
    public snackbar?: MatSnackBar,
    public dialog?: MatDialog,
    public meta?: Meta,
    public title?: Title,
    public route?: ActivatedRoute,
    public sharedService?: SharedService) {

    super(translate, snackbar, dialog);
  }

  ngOnInit() {
    this.sharedService.showSidenav.next(this.showSidenav);

    this.sharedService.showTemplate.next(true);

    this.setBreadcrumbs();

    if (this.section && this.meta && this.title) {
      this.getTranslations([this.section]).then(() => {
        if (this.translations[this.section] && this.translations[this.section]['metas']) {
          this.setMetas(
            this.translations[this.section]['metas']['title'],
            this.translations[this.section]['metas']['description'],
            this.translations[this.section]['metas']['keywords']
          );
        } else {
          this.setMetas(
            this.translations['general']['projectName'],
            this.translations['general']['projectName'],
            this.translations['general']['keywords']
          );
        }
        if (this.translations[this.section] && this.translations[this.section]['title']) {
          this.sectionTitle = this.translations[this.section]['title'];
        }
      }).catch(err => {
        console.log('err get translations', err);
      });
    }
  }

  setMetas(title, description?, keywords?) {
    // console.log('cambia metas', title, description, keywords);

    setTimeout(() => {
      if (this.translations['general']) {
        // this.title.setTitle(this.translations['general']['projectName'] + ' :: ' + title);
        this.title.setTitle(title + ' - ' + this.translations['general']['projectName']);
      }
      this.meta.updateTag({
        name: 'description', content: description ? description : title
      });
      if (keywords) {
        this.meta.updateTag({
          name: 'keywords', content: keywords
        });
      } else {
        if (this.translations['general']) {
          this.meta.updateTag({
            name: 'keywords', content: this.translations['general']['keywords']
          });
        }
      }
    });
  }

  // setSection(title) {
  //     if (this.route && this.route.routeConfig && this.route.routeConfig.data) {
  //         this.route.routeConfig.data['section'] = title;
  //         this.sharedService.reloadBreadcrumbs.next(this.route.root);
  //     }
  // }

  setBreadcrumbs() {
    this.sharedService.reloadBreadcrumbs.next(this.breadcrumbs);
  }

  _copyUrl(url) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackbar(this.translations['general'].urlCopiedClipboard);
  }

}
