import { SharedService } from '@web/shared/shared.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { IBreadCrumb } from '@web/base/shared/models/breadcrumb.model';

@Component({
  selector: 'web-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {

  private innerHeight: number;

  public fixed = false;
  private offsetHeader = ConstantsProject.HEADER_HEIGHT;

  public breadcrumbs: Array<IBreadCrumb> = [];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  constructor(
    public translate: TranslateService,
    private sharedService: SharedService,
    private sc: ScrollDispatcher,
    private ref: ChangeDetectorRef,
  ) {
    super(translate);

    this.innerHeight = window.innerHeight;
  }

  ngOnInit() {
    this.subscriptions.push(this.sharedService.reloadBreadcrumbs$.subscribe((breadcrumbs: Array<IBreadCrumb>) => {
      setTimeout(() => {
        this.breadcrumbs = breadcrumbs;
      });
    }));

    this.sc.scrolled().subscribe(() => {
      this.fixed = (scrollY >= this.offsetHeader && this.innerHeight > ConstantsProject.LIMIT_HEIGHT_TO_FIXED_MENU);
      this.ref.detectChanges();
    });
  }
}
