import { MenuService } from './../menu.service';
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, HostListener } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../menu-item.model';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { BaseComponent } from 'utils';
import { SharedService } from '@web/base/shared/shared.service';
import { IResponse } from '@web/base/shared/models/response.model';
import { SlugifyPipe } from '@web/base/shared/pipes/slugify.pipe';

@Component({
  selector: 'web-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuHeaderComponent extends BaseComponent implements OnInit {

  private innerHeight: number;
  private offsetHeader = ConstantsProject.HEADER_HEIGHT;
  public fixed = false;
  public menuHeader: MenuItem[];
  // observatories;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  constructor(
    public translate: TranslateService,
    private sc: ScrollDispatcher,
    private ref: ChangeDetectorRef,
    private menuService: MenuService,
    private sharedService: SharedService,
    public slugPipe: SlugifyPipe
  ) {
    super(translate);

    this.innerHeight = window.innerHeight;

  }

  ngOnInit() {
    this.getTranslations(['menu']).then(() => {
      this.menuHeader = this.menuService.getMenuHeader();

      // Añadimos al menú los observatorios de forma dinámica.
      this.sharedService.loadObservatories(true).subscribe((res: IResponse) => {
        const menuItemObservatories = this.menuHeader.find(o => o.title === 'observatories');

        res.result.items.forEach(obser => {
          let item: MenuItem;
          item = { title: obser.name, translate: false, url: '/observatorios/' + obser.observatoryID };

          if (menuItemObservatories.children && obser.name.includes('Obser')) {
            menuItemObservatories.children.push(item);
          }
        });
      });

      // Añadimos al menú las consultas de forma dinámica.
      this.sharedService.loadContactTypes().subscribe((res: IResponse) => {
        const menuItemContacts = this.menuHeader.find(o => o.title === 'queries');

        res.result.items.forEach(contactType => {
          let item: MenuItem;
          item = { title: contactType.name, translate: false, url: '/consultas/' + contactType.contactTypeID + '/' + this.slugPipe.transform(contactType.name) };
          if (menuItemContacts.children) {
            menuItemContacts.children.push(item);
          }
        });
      });

      // Añadimos al menú las portales de forma dinámica.
      const portals = ConstantsProject.PORTALS;
      const menuItemPortals = this.menuHeader.find(o => o.title === 'portals');

      this.getTranslations(['menu']).then(() => {
        portals.map(t => t.textReadable = this.translations['menu'][t.text]);

        portals.forEach(portal => {
          let item: MenuItem;
          item = { title: portal.textReadable, translate: false, url: '/portales/' + portal.value + '/' + this.slugPipe.transform(portal.textReadable) };
          if (menuItemPortals.children) {
            menuItemPortals.children.push(item);
          }
        });
      });

    });

    // const self = this;

    this.sc.scrolled().subscribe(() => {
      // console.log('scroll self', self);

      // self.fixed = (scrollY >= this.offsetHeader && self.innerHeight > ConstantsProject.LIMIT_HEIGHT_TO_FIXED_MENU);
      // self.ref.detectChanges();

      this.fixed = (scrollY >= this.offsetHeader && this.innerHeight > ConstantsProject.LIMIT_HEIGHT_TO_FIXED_MENU);
      this.ref.detectChanges();
    });
  }

}
