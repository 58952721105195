import { MenuItem } from '../core/menu/menu-item.model';

export class Constants {

  static TOKEN_KEY = '9928aa619a05a330f1c0c9c41b907c95';

  static COOKIES_PREFIX = '__Cosital_';
  static GOOGLE_ANALYTICS4_ID = 'CEFNPY1XSY';

  static DISPOSITION_LIST = 1;
  static DISPOSITION_GRID = 2;
  static DISPOSITION_JUMBOTRON = 3;
  static DISPOSITION_CAROUSEL = 4;

  static LIMIT_HEIGHT_TO_FIXED_MENU = 840;
  static HEADER_HEIGHT = 200;

  static MENU_SIDENAV: Array<MenuItem> = [];
  static MENU_HEADER: Array<MenuItem> = [];

  static MAX_SIZE_ATTACHMENTS = 10 * 1024 * 1024;
  static ATTACHMENT_TYPE_FILE = 1;
  static ATTACHMENT_TYPE_IMAGE = 2;
  static ATTACHMENT_TYPE_VIDEO = 3;

  static SUBSCRIPTION_TYPE_FHN = 1;
  static SUBSCRIPTION_TYPE_NO_FHN = 2;

  static ARTICLE_TYPE_SUBSCRIPTION = 1;
  static ARTICLE_TYPE_OBSERVATORY = 2;
  static ARTICLE_TYPE_COURSE = 3;
  static ARTICLE_TYPE_PRODUCT = 4;

  static COURSE_TYPE_ONLINE = 1;
  static COURSE_TYPE_ONSITE = 2;
  static COURSE_TYPE_DECENTRALIZED = 3;

  static COURSE_INSCRIPTION_STATUSES = [
    { value: 1, text: 'inscribed' },
    { value: 2, text: 'reserve' },
    { value: 3, text: 'cancelled' },
  ];

  static PURCHASES_STATUSES = [
    { value: 1, text: 'pending' },
    { value: 2, text: 'confirmed' },
    { value: 3, text: 'paid' },
    { value: 4, text: 'cancelled' },
    { value: 5, text: 'rejected' }
  ];

  static ADDRESS_MAIN = 1;

  static BANNER_SIDE = 1;

  static CATEGORY_STATIC = 13;

  static GENDERS = [
    {
      value: 'm',
      text: 'man',
      textReadable: ''
    },
    {
      value: 'f',
      text: 'woman',
      textReadable: ''
    }
  ];

  static DOCUMENT_TYPES_CIF = 'cif';
  static DOCUMENT_TYPES = [
    {
      value: 'dni',
      text: 'dni',
      textReadable: ''
    },
    {
      value: 'nie',
      text: 'nie',
      textReadable: ''
    },
    {
      value: 'cif',
      text: 'cif',
      textReadable: ''
    }
  ];

  static PORTALS = [
    {
      value: 9,
      text: 'portal1',
      textReadable: ''
    },
    {
      value: 11,
      text: 'portal2',
      textReadable: ''
    },
    {
      value: 12,
      text: 'portal3',
      textReadable: ''
    }
  ];

  static WAYPAYDOMICILIED = 1;

  static PRODUCT_CATEGORY_LIBRARY = 1;
  static PRODUCT_CATEGORY_SUBSCRIPTION = 2;

  static COVID_GROUPS = [
    {
      text: 'administration',
      textReadable: '',
      url: 'contenidos/7303/crisis-covid-19-administracion-electronica-y-transparencia'
    },
    {
      text: 'publicProcurement',
      textReadable: '',
      url: 'contenidos/7304/crisis-covid-19-contratacion-publica'
    },
    {
      text: 'control',
      textReadable: '',
      url: 'contenidos/7305/crisis-covid-19-control-interno'
    },
    {
      text: 'indebtedness',
      textReadable: '',
      url: 'contenidos/7306/crisis-covid-19-estabilidad-endeudamiento-y-subvenciones'
    },
    {
      text: 'heritage',
      textReadable: '',
      url: 'contenidos/7307/crisis-covid-19-patrimonio-urbanismo-y-medio-ambiente'
    },
    {
      text: 'personal',
      textReadable: '',
      url: 'contenidos/7308/crisis-covid-19-personal'
    },
    {
      text: 'treasury',
      textReadable: '',
      url: 'contenidos/7309/crisis-covid-19-tesoreria'
    },
    {
      text: 'externalReports',
      textReadable: '',
      url: 'contenidos/7310/crisis-covid-19-informes-externos'
    }
  ];

  static COMMUNITY_FUNDS_ID = 13;

  // Variables que determinan si el login necesita un texto y una redirección a algún sitio, registro de usuarios, compra de suscripción, ...
  static URLS_TYPES_PRODUCTS = 'productos';
  static URLS_TYPES_CONTACTS = 'consultas';
  static URLS_TYPES_COURSES = 'cursos';

  // Variables que determinan el tipo de elemento que se muestra en el carrusel.
  // Es el relacionableElementID de la tablarelacionableElements
  static COVER_ELEMENT_TYPE_CONTENTS = 1;
  static COVER_ELEMENT_TYPE_BANNERS = 2;
  static COVER_ELEMENT_TYPE_COURSES = 3;

  // Variables que determinan el tipo de elemento del buscador.
  static SEARCH_CATEGORY_CONTACTS = 'contacts';
  static SEARCH_CATEGORY_CONTENTS = 'contents';
  static SEARCH_CATEGORY_MODELS = 'models';
  static SEARCH_CATEGORY_COURSES = 'courses';
  static SEARCH_CATEGORY_PRODUCTS = 'products';

  static SEARCH_CATEGORIES = [
    {
      value: 'contents',
      text: 'contents',
      textReadable: ''
    },
    {
      value: 'models',
      text: 'models',
      textReadable: ''
    },
    {
      value: 'contacts',
      text: 'contacts',
      textReadable: ''
    },
    {
      value: 'courses',
      text: 'courses',
      textReadable: ''
    },
    {
      value: 'products',
      text: 'products',
      textReadable: ''
    }
  ];

  // Caracteres minimos para que se active el Buscador
  static MIN_LENGTH_TO_SEARCH = 3;

  // redes sociales
  static SHARED_FACEBOOK = 1;
  static SHARED_WHATSAPP = 2;
  static SHARED_TWITTER = 3;
  static SHARED_LINKED_IN = 4;
  static SHARED_EMAIL = 5;

  static CONTENTS_OBSERVATORIES_PORTALS = 1;
  static CONTENTS_NEWS = 2;
  static CONTACTS_FEATURED = 3;
  static CONTACTS_BEST_RATINGS = 4;

  static MORE_CONTENTS = [
    {
      value: Constants.CONTENTS_OBSERVATORIES_PORTALS,
      title: 'latestUpdates',
      titleReadable: '',
      contacts: false
    },
    {
      value: Constants.CONTENTS_NEWS,
      title: 'news',
      titleReadable: '',
      contacts: false
    },
    {
      value: Constants.CONTACTS_FEATURED,
      title: 'featuredQueries',
      titleReadable: '',
      contacts: true
    },
    {
      value: Constants.CONTACTS_BEST_RATINGS,
      title: 'ratingQueries',
      titleReadable: '',
      contacts: true
    }
  ];

  constructor() { }

}
