import { Component, OnInit } from '@angular/core';
import { BaseWebComponent } from '../base-web/base-web.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../shared.service';
import { AuthService } from '@web/base/auth/auth.service';
import { CoreService } from '@web/base/core/core.service';
import { IResponse } from '../../models/response.model';
import { debounceTime } from 'rxjs/operators';
import { validateDNI, validateCIF } from 'utils';

import { SimpleSnackbarComponent } from 'utils';
import { Observable, Subject } from 'rxjs';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { validatePhone, checkForm } from '../../../../../../../../libraries/utils/src/public_api';


@Component({
  selector: 'web-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent extends BaseWebComponent implements OnInit {

  public form: FormGroup;

  elementTypeID: number; // Tipo de elemento (suscripción FHN, observatorio, producto o curso)
  elementID: number; // ID del elemento que estamos comprando (id observatorio, id producto, id curso)

  baseUrl: Array<string> = []; // Array de partes de la ruta que se utilizará para redirigir al usuario a la página de resultado.

  addresses = []; // Listado de direcciones disponibles para la compra
  waysPays = []; // Formas de pago disponibles para la compra
  WAYPAYDOMICILIED = ConstantsProject.WAYPAYDOMICILIED;

  purchaseData; // Información de precios de la compra.

  item; // Elemento que se está comprando (por ejemplo, curso)

  // IBAN
  checkIban = new Subject();
  checkIban$ = this.checkIban.asObservable();

  documentTypes = ConstantsProject.DOCUMENT_TYPES;
  localities = [];

  addressSelectedHTML;
  addressSelected: any = {};
  wayPaySelected: any = {};

  public isSaving = false;

  constructor(
    translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public authService: AuthService,
    public coreService: CoreService,
    public router: Router
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }


  ngOnInit() {
    super.ngOnInit();

    this.getTranslations(['purchases']).then(() => {
      this.documentTypes.map(g => g.textReadable = this.translations['general'][g.text]);
    });

    this.createForm();

    this.subscriptions.push(
      this.coreService.templateLoaded$.subscribe(v => {
        if (v && v.result.user && v.result.user.userWebID) {
          this.sharedService.loadUserAddresses().subscribe((res: IResponse) => {
            this.addresses = res.result.items;

            const userWebAddressMain = this.addresses.find(a => a.main);
            if (userWebAddressMain) {
              this.form.controls['userWebAddressID'].setValue(userWebAddressMain.userWebAddressID);
              this.form.controls['iban'].setValue(userWebAddressMain.IBAN);
              this.form.controls['documentType'].setValue(userWebAddressMain.documentType);
              this.form.controls['userWebAddressID'].markAsTouched();
            }

            if (this.addresses.length !== 0) {
              // Si tiene dirección quito las validaciones de los campos cuando no tienen dirección
              this.form.controls['userWebAddressID'].setValidators([Validators.required, this.validateAddress.bind(this)]);
              this.form.controls['title'].setValidators(null);
              this.form.controls['title'].updateValueAndValidity();
              this.form.controls['entity'].setValidators(null);
              this.form.controls['entity'].updateValueAndValidity();
              this.form.controls['cp'].setValidators(null);
              this.form.controls['cp'].updateValueAndValidity();
              this.form.controls['localityID'].setValidators(null);
              this.form.controls['localityID'].updateValueAndValidity();
              this.form.controls['address'].setValidators(null);
              this.form.controls['address'].updateValueAndValidity();
              this.form.controls['documentType'].setValidators(null);
              this.form.controls['documentType'].updateValueAndValidity();
              this.form.controls['documentNumber'].setValidators(null);
              this.form.controls['documentNumber'].updateValueAndValidity();
              this.form.controls['codeDIR'].setValidators(null);
              this.form.controls['codeDIR'].updateValueAndValidity();
              this.form.controls['managingBody'].setValidators(null);
              this.form.controls['managingBody'].updateValueAndValidity();
              this.form.controls['processingUnit'].setValidators(null);
              this.form.controls['processingUnit'].updateValueAndValidity();
              this.form.controls['accountingOffice'].setValidators(null);
              this.form.controls['accountingOffice'].updateValueAndValidity();
              this.form.controls['phone'].setValidators(null);
              this.form.controls['phone'].updateValueAndValidity();
            } else {
              // Añado los campos requeridos cuando no tiene dirección
              this.form.controls['title'].setValidators([Validators.required]);
              this.form.controls['title'].updateValueAndValidity();
              this.form.controls['entity'].setValidators([Validators.required]);
              this.form.controls['address'].setValidators([Validators.required]);
              this.form.controls['localityID'].setValidators([Validators.required]);
              this.form.controls['documentType'].setValidators([Validators.required]);
              this.form.controls['documentNumber'].setValidators([Validators.required, Validators.maxLength(45), validateDNI]);
              this.form.controls['codeDIR'].setValidators([Validators.requiredTrue]);
              this.form.controls['managingBody'].setValidators([Validators.required]);
              this.form.controls['processingUnit'].setValidators([Validators.required]);
              this.form.controls['accountingOffice'].setValidators([Validators.required]);
              this.form.controls['userWebAddressID'].setValidators(null);
              this.form.controls['nameProvince'].disable();
              this.form.controls['nameCommunity'].disable();
              this.form.controls['nameCountry'].disable();
              this.form.controls['phone'].setValidators([Validators.required, validatePhone]);
              this.form.controls['phone'].updateValueAndValidity();
            }
            this.form.controls['userWebAddressID'].updateValueAndValidity();
            this.form.controls['bic'].disable();
            this.form.controls['entityBank'].disable();
          });
        }
      }));

    this.form.controls['documentType'].valueChanges.subscribe(v => {

      const validators = [Validators.required, Validators.maxLength(45)];
      switch (v) {
        case 'dni':
          validators.push(validateDNI);
          this.form.controls['codeDIR'].setValidators(null);
          this.form.controls['codeDIR'].setValue(false);
          break;

        case 'nie':
          validators.push(validateDNI);
          this.form.controls['codeDIR'].setValidators(null);
          this.form.controls['codeDIR'].setValue(false);
          break;

        case 'cif':
          validators.push(validateCIF);
          this.form.controls['codeDIR'].setValue(true);
          this.form.controls['codeDIR'].setValidators([Validators.requiredTrue]);
          break;

        default:
          break;
      }

      this.form.controls['documentNumber'].setValidators(validators);
      this.form.controls['documentNumber'].updateValueAndValidity();
      this.form.controls['codeDIR'].updateValueAndValidity();

      this.sharedService.loadWayPaysByDocumentType(v).subscribe((res: IResponse) => {
        this.waysPays = res.result;
        // Si sólo puede elegir una forma de pago la seleccionamos
        if (this.waysPays.length === 1) {
          this.form.controls['wayPayID'].setValue(this.waysPays[0].wayPayID);
        }
      });

      const value = this.form.value;
      value['documentType'] = v;
      this.calculatePrice(value);
      this.buildAddressDinamic();

    });

    this.form.controls['documentNumber'].valueChanges.subscribe(v => {
      const value = this.form.value;
      value['documentNumber'] = v;
      this.calculatePrice(value);
      this.buildAddressDinamic();
    });

    // Cuando el código postal tenga 4 digitos empezamos a buscar las localidades
    this.form.controls['cp'].valueChanges.subscribe(c => {
      if (c && ('' + c).length > 3) {
        this.sharedService.loadLocaties(c).subscribe((res: IResponse) => {
          this.localities = res.result.items;
        });
      }
      this.buildAddressDinamic();
    });

    // Cuando la dirección cambiamos la dirección de HTML
    this.form.controls['address'].valueChanges.subscribe(v => {
      this.buildAddressDinamic();
    });


    // Cuando la dirección 2 cambiamos la dirección de HTML
    this.form.controls['address2'].valueChanges.subscribe(v => {
      this.buildAddressDinamic();
    });


    // Cuando la localidad cambie, se cambia la provincia, el comunidad autónoma y el pais.
    this.form.controls['localityID'].valueChanges.subscribe(v => {
      if (this.localities) {
        this.localities.find(l => {
          this.form.controls['nameProvince'].setValue(l.nameProvince);
          this.form.controls['nameCommunity'].setValue(l.nameCommunity);
          this.form.controls['nameCountry'].setValue(l.nameCountry);
        });
      }
      this.buildAddressDinamic();
    });

    // Sí el check Código DIR esta marcado, los 3 campos de abajo serán obligatorios
    this.form.controls['codeDIR'].valueChanges.subscribe(c => {
      if (c) {
        this.form.controls['managingBody'].setValidators([Validators.required]);
        this.form.controls['processingUnit'].setValidators([Validators.required]);
        this.form.controls['accountingOffice'].setValidators([Validators.required]);
      } else {
        this.form.controls['managingBody'].setValidators(null);
        this.form.controls['processingUnit'].setValidators(null);
        this.form.controls['accountingOffice'].setValidators(null);
      }
      this.form.controls['managingBody'].updateValueAndValidity();
      this.form.controls['processingUnit'].updateValueAndValidity();
      this.form.controls['accountingOffice'].updateValueAndValidity();
    });

    this.form.controls['iban'].valueChanges.pipe(
      debounceTime(800)
    ).subscribe(v => {
      if (v) {
        const field1 = v.substr(4, 4);

        if (v.substr(0, 2) === 'ES' && v.length === 24 && field1.length === 4) {
          this.sharedService.loadEntities(field1).subscribe((data: IResponse) => {
            if (data.result) {
              this.form.controls['bic'].setValue(data.result.bic);
              this.form.controls['entityBank'].setValue(data.result.name);
            }
          });
        } else {
          this.form.controls['bic'].setValue(null);
          this.form.controls['entityBank'].setValue(null);
        }
      }
    });

    this.form.controls['discount'].valueChanges.pipe(
      debounceTime(800)
    ).subscribe(v => {

      const value = this.form.value;
      value['discount'] = v;

      this.calculatePrice(value);
    });

    this.form.controls['wayPayID'].valueChanges.subscribe(v => {
      this.checkIban.next();

      if (this.waysPays) {
        this.wayPaySelected = this.waysPays.filter(w => w.wayPayID === v)[0];
      }
      if (v === ConstantsProject.WAYPAYDOMICILIED) {
        // this.form.controls['iban'].setValidators([Validators.required]);
        if (this.form.controls['userWebAddressID']) {
          this.form.controls['iban'].setValue(this.addressSelected.iban);
        }
      } else {
        // this.form.controls['iban'].setValidators(null);
        this.form.controls['iban'].setValue(null);
      }
      // this.form.controls['iban'].updateValueAndValidity();
    });

    this.form.controls['title'].valueChanges.subscribe(v => {
      this.buildAddressDinamic();
    });

    this.form.controls['userWebAddressID'].valueChanges.subscribe(v => {

      this.form.controls['wayPayID'].setValue(null);

      this.sharedService.loadWayPaysByAddressID(v).subscribe((res: IResponse) => {
        this.waysPays = res.result.items;
        // Si sólo puede elegir una forma de pago la seleccionamos
        if (this.waysPays.length === 1) {
          this.form.controls['wayPayID'].setValue(this.waysPays[0].wayPayID);
        }
      });

      const value = this.form.value;
      value['userWebAddressID'] = v;

      this.calculatePrice(value);

      const address = this.addresses.filter(a => a.userWebAddressID === v)[0];
      this.addressSelected = address;
      if (address) {
        this.addressSelectedHTML =
          address.address + ' '
          + (address.address2 || '') + ' '
          + address.cp + ' '
          + address.nameLocality
          + ' (' + address.nameProvince + ')';
      } else {
        this.addressSelectedHTML = '';
      }
    });
  }

  buildAddressDinamic() {
    if (!this.form.controls['userWebAddressID'].value) {
      const locality = this.localities.find(l => l.localityID === this.form.controls['localityID'].value);

      this.addressSelectedHTML =
        this.form.controls['address'].value + ' '
        + (this.form.controls['address2'].value || '') + ' '
        + this.form.controls['cp'].value + ' '
        + (locality ? locality.name : '') + ' '
        + ' (' + this.form.controls['nameProvince'].value + ')';

      const address = {
        title: this.form.controls['title'].value,
        entity: this.form.controls['entity'].value,
        documentType: this.form.controls['documentType'].value,
        documentNumber: this.form.controls['documentNumber'].value,
        address: this.form.controls['address'].value,
        address2: this.form.controls['address2'].value,
        nameLocality: (locality ? locality.name : '') + ' ',
        nameProvince: this.form.controls['nameProvince'].value,
        cp: this.form.controls['cp'].value
      };
      this.addressSelected = address;
    }
  }

  createForm() {
    this.form = new FormGroup({
      elementTypeID: new FormControl(this.elementTypeID, [Validators.required]),
      elementID: new FormControl(this.elementID, [Validators.required]),
      userWebAddressID: new FormControl(null, this.validateAddress.bind(this)),
      title: new FormControl(null),
      entity: new FormControl(null),
      address: new FormControl(null),
      address2: new FormControl(null),
      cp: new FormControl(null),
      localityID: new FormControl(null),
      nameProvince: new FormControl(null),
      nameCommunity: new FormControl(null),
      nameCountry: new FormControl(null),
      phone: new FormControl(null),
      documentType: new FormControl(null),
      documentNumber: new FormControl(null),
      codeDIR: new FormControl(true),
      managingBody: new FormControl(null),
      processingUnit: new FormControl(null),
      accountingOffice: new FormControl(null),
      contractNumber: new FormControl(null),
      iban: new FormControl(null),
      bic: new FormControl(null),
      entityBank: new FormControl(null),
      wayPayID: new FormControl(null, [Validators.required]),
      discount: new FormControl('', null, [this.validateCodeDiscount.bind(this)]),
      observations: new FormControl(''),
      conditions: new FormControl(false, Validators.requiredTrue),
      terms: new FormControl(false, Validators.requiredTrue)
    });
  }

  validateAddress(c: FormControl) {
    if (c.value) {
      const address = this.addresses.find(a => a.userWebAddressID === c.value);
      if (address) {
        if (!address.localityID) {
          return { errorLocality: true };
        }
      }
    }
    return null;
  }

  validateCodeDiscount(c: FormControl) {
    return new Promise((resolve, reject) => {
      if (c.value) {
        this.sharedService.codeDiscountValid(c.value, this.elementTypeID, this.elementID)
          .pipe(debounceTime(800))
          .subscribe(
            (res: IResponse) => {
              if (res.result.valid === 0) {
                resolve({ codeDiscountIncorrect: true });
              } else {
                resolve(null);
              }
            }, err => reject(err));
      } else {
        resolve(null);
      }
    });
  }

  submit() {

    this.checkIban.next();

    checkForm(this.form);

    if (this.form.valid) {
      this.isSaving = true;

      const value = Object.assign({}, this.form.value);
      delete value['conditions'];
      delete value['terms'];

      this.createPurchase(value).subscribe(res => {
        this.isSaving = false;

        const wayPayTpv = this.waysPays.find(w => w.alias === 'tpv');

        if (wayPayTpv && value.wayPayID === wayPayTpv.wayPayID) {
          this.goToTpv([]);
        } else {
          this.goToResultOK([]);
        }
      }, () => {
        this.openSnackbar(this.translations['purchases']['error'], SimpleSnackbarComponent.TYPE_ERROR);
        this.isSaving = false;
      });
    }
  }

  openDataProtection() {
    this.openSimpleDialog(this.translations['core']['dataProtection'], this.translations['core']['dataProtectionContentRegister']);
  }

  goToResultOK(extraUrl) {
    this.router.navigate(this.baseUrl.concat(extraUrl).concat(['ok']));
  }

  goToTpv(extraUrl) {
    this.router.navigate(this.baseUrl.concat(extraUrl).concat(['tpv']));
  }

  // Funciones a sobreescribir por el componente que herede éste

  calculatePrice(value) { }

  createPurchase(value): Observable<any> {
    return new Observable();
  }

  // Elimina todos los campos de la dirección de facturación
  clearAddress() {
    this.form.controls['title'].setValue(null);
    this.form.controls['entity'].setValue(null);
    this.form.controls['documentType'].setValue(null);
    this.form.controls['documentNumber'].setValue(null);
    this.form.controls['address'].setValue(null);
    this.form.controls['address2'].setValue(null);
    this.form.controls['cp'].setValue(null);
    this.form.controls['localityID'].setValue(null);
    this.form.controls['nameProvince'].setValue(null);
    this.form.controls['nameCommunity'].setValue(null);
    this.form.controls['nameCountry'].setValue(null);
    this.form.controls['phone'].setValue(null);
    this.form.controls['codeDIR'].setValue(null);
    this.form.controls['managingBody'].setValue(null);
    this.form.controls['processingUnit'].setValue(null);
    this.form.controls['accountingOffice'].setValue(null);
    this.form.controls['contractNumber'].setValue(null);
    this.form.controls['iban'].setValue(null);
  }

  // Función que autorrellena los datos de la entidad suscriptora
  fillAddress(value) {
    this.form.controls['title'].setValue(value.title);
    this.form.controls['entity'].setValue(value.entity);
    this.form.controls['documentType'].setValue(value.documentType);
    this.form.controls['documentNumber'].setValue(value.documentNumber);
    this.form.controls['address'].setValue(value.address);
    this.form.controls['address2'].setValue(value.address2);
    this.form.controls['cp'].setValue(value.cp);
    this.form.controls['localityID'].setValue(value.localityID);
    this.form.controls['nameProvince'].setValue(value.province);
    this.form.controls['nameCommunity'].setValue(value.community);
    this.form.controls['nameCountry'].setValue(value.country);
    this.form.controls['phone'].setValue(value.phone);
    this.form.controls['codeDIR'].setValue(value.codeDIR === 1 ? true : false);
    this.form.controls['managingBody'].setValue(value.managingBody);
    this.form.controls['processingUnit'].setValue(value.processingUnit);
    this.form.controls['accountingOffice'].setValue(value.accountingOffice);
    this.form.controls['contractNumber'].setValue(value.contractNumber);
    this.form.controls['iban'].setValue(value.iban);

    checkForm(this.form);
  }

  goToAddressess(userWebAddressID) {
    const urlRedirect = this.router.url.replace('/directo', '');
    if (userWebAddressID) {
      this.router.navigate(['/usuarios/direccion', userWebAddressID], { queryParams: { returnUrl: urlRedirect } });
    } else {
      this.router.navigate(['/usuarios/direccion'], { queryParams: { returnUrl: urlRedirect } });
    }
  }

}
