import { Component, Input } from '@angular/core';

@Component({
  selector: 'web-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {

  @Input('text') text: string;
  
  constructor() {}
}
